import { HiLocationMarker } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import people from '../../assets/imgs/people.png'
import { univsAssetLink } from '../SectionCard'

const Banner = styled.div`
   background-image: url(${(props) => (props.bg ? props.bg : people)});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`
export default function UnivCard({ data }) {
   return (
      <Link
         to={'' + data?.id_university}
         className="h-6/12 flex h-full max-h-50 min-w-60 flex-row justify-center rounded-xl bg-white shadow-xs hover:bg-bg"
      >
         <Banner
            bg={univsAssetLink + data?.cover}
            className="h-full w-full rounded-l-xl p-2 "
         ></Banner>
         <hr className="border-zinc00 border" />
         <Description
            title={data?.university_name}
            desc={data?.description}
            country={data?.country + '/' + data?.district}
         />
      </Link>
   )
}

function Description({ title, desc, country }) {
   return (
      <div className="gap- flex h-full w-full flex-col rounded-b-xl px-4 pb-2 pt-4">
         <div className="items-left flex flex-col justify-between gap-1">
            <span className="font-semibold">
               {' '}
               {title ? title : 'Université Lumiere de Bujumbura'}
            </span>
            <div className="flex items-center gap-1 rounded border px-2  py-1 text-3">
               <HiLocationMarker />
               <span>{country ? country : 'Bukavu, DRC'}</span>
            </div>
         </div>
         <span className="overflow-hidden text-3">
            {desc
               ? desc
               : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur oloribus perspiciatis atque nemo? Debitis sint minima dolore labore, quaerat enim accusamus est at quae exercitationem doloribus quas dolore nesciunt iusto.'}
         </span>
         <div className="h-4/12 mr-2 mt-2 flex w-full items-center justify-center rounded-xl border border-primary py-1 text-primary hover:bg-primary hover:text-white">
            <span className="py- cursor-pointer rounded-2xl px-2 text-3 font-semibold md:text-4">
               S'inscrire
            </span>
         </div>
      </div>
   )
}
