import { useEffect, useState } from 'react'

const FallbackUI = () => (
   <div>
      <h2>Something went wrong.</h2>
      {/* Display a user-friendly message or additional information */}
   </div>
)

const logErrorToService = (error, errorInfo) => {
   // Log the error to an error reporting service
   console.error('Error:', error)
   console.error('Error Info:', errorInfo)
}

const ErrorBoundary = ({ children }) => {
   const [hasError, setHasError] = useState(false)

   useEffect(() => {
      const errorHandler = (error, errorInfo) => {
         setHasError(true)
         logErrorToService(error, errorInfo)
      }

      window.addEventListener('error', errorHandler)

      return () => {
         window.removeEventListener('error', errorHandler)
      }
   }, [])

   if (hasError) {
      return <FallbackUI />
   }

   return children
}

export default ErrorBoundary
