import axios from 'axios'
import {
   Button,
   Datepicker,
   Label,
   Modal,
   Select,
   Spinner,
   TextInput,
} from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiMail, HiPhone } from 'react-icons/hi'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { PopUpContext } from '../../utilis/Context'
import { File } from '../Form'
import { MyLoader } from '../Section'
import { univsAssetLink } from '../SectionCard'

const Banner = styled.div`
   background-image: url('${(props) => props.bg}');
   background-position: center;
   background-repeat: no-repeat;

   //    height : 100%;
   width: 100%;
   // background-color: red;
`

export default function UnivDetails({ data }) {
   const [univ, setUniv] = useState()
   const [modalState, setModalStare] = useState(false)
   const [countries, setCountries] = useState(null)
   const params = useParams()
   // console.log(data)

   useEffect(() => {
      ///axios
      axios
         .get('https://restcountries.com/v2/all?fields=name')
         .then(function (response) {
            // handle success
            // console.log(response.data);
            setCountries(response.data)
            // console.log(response.data)
            // setCountriesLoader(false)
         })
         .catch(function (error) {
            // handle error
            // console.log(error)
         })
         .finally(function () {
            // always executed
         })
   }, [])
   // const [modalState, setModalStare] = useState(false)

   useEffect(() => {
      data && loadData()
   }, [data])

   const loadData = () => {
      data &&
         setUniv(
            data.find(({ id_university }) => {
               return Number(id_university) === Number(params.id)
            }),
         )
   }

   const openModal = () => {
      setModalStare(true)
   }
   const closeModal = () => {
      setModalStare(false)
   }
   return (
      <div className="flex h-full w-full flex-col  items-center rounded-xl border bg-white p-4 ">
         {data && univ ? (
            <div className="w-ful flex flex-col items-center gap-2 md:w-10/12">
               <div className="flex w-full justify-between pt-4">
                  <span className="rounded border px-2 py-1 text-5 font-bold">
                     {univ
                        ? univ?.university_name && univ?.university_name
                        : 'La Méthamorphose'}
                  </span>
               </div>
               <span className="w-full cursor-pointer rounded-lg py-2 text-4 font-semibold md:text-5">
                  Optenez votre Inscription Maintenant
               </span>
               <div className="flex h-60 w-full  justify-center  overflow-hidden rounded-xl border md:h-120">
                  <Banner
                     bg={univsAssetLink + univ?.cover}
                     // className="h-50 md:h-100 bg-white"
                     // alt="article_image"
                     // srcSet=""
                  />
               </div>
               <div className="flex w-full flex-row items-center justify-between gap-1 p-2 md:p-5">
                  <span className="w-full text-4 font-semibold md:text-5">
                     {univ
                        ? univ?.university_name && univ?.university_name
                        : 'La Méthamorphose'}
                  </span>
                  {/* <div className="w-full flex flex-col sm:flex-row items-center justify-between"> */}
                  {/* <div className="w-full flex flex-row gap-1 items-center justify-end pl-2 md:pl-5 "> */}
                  {/* <span className="material-icons-round">ticket</span> */}
                  {univ?.faculties?.length > 0 && (
                     <span
                        onClick={() => openModal()}
                        className="cursor-pointer rounded-lg bg-primary px-4 py-2 text-4 font-semibold text-white md:text-5"
                     >
                        S'Inscrire
                     </span>
                  )}
                  {/* </div> */}
                  {/* </div> */}
               </div>

               <div className="p-4">
                  <span className="text font-semibold">
                     Résumé sur cet Etablissement :
                  </span>
                  <p className="font-base mb-2">
                     {univ?.description
                        ? univ.description
                        : `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                     Animi id aperiam, nihil vitae dolorem molestiae qui
                     incidunt aspernatur molestias, itaque minus, sed porro
                     pariatur illo enim autem fuga eaque odit.`}
                  </p>
                  {univ?.faculties?.length > 0 && (
                     <>
                        <span className="font-semibold">
                           Facultés Organisées :
                        </span>
                        <ul className="list-disc pl-6">
                           {univ.faculties.map(({ faculty_name, program }) => (
                              <li className="font-semibold">
                                 {faculty_name + ' - ' + program}
                              </li>
                           ))}
                           {/* <li>
                        {univ.faculties[0].faculty_name +
                           ' - ' +
                           univ.faculties[0].program}
                     </li> */}
                        </ul>
                     </>
                  )}
                  {/* <span className="font-semibold">
                     Document Requis Pour L'inscription :
                  </span>
                  <ul className="list-disc pl-6">
                     <li>DROIT CIVIL</li>
                     <li>INFORMATIQUE DE GESTION</li>
                     <li>SICENECES INFIRMIERE</li>
                     <li>GENIE CIVIL</li>
                     <li>ISPGECO</li>
                  </ul> */}
                  <PopUpContext.Consumer>
                     {({ openPopUp }) => (
                        <Reserve
                           data={univ && univ}
                           modalState={modalState}
                           closeModal={closeModal}
                           countries={countries}
                           setPopUp={openPopUp}
                        />
                     )}
                  </PopUpContext.Consumer>
               </div>
            </div>
         ) : (
            <MyLoader />
         )}
      </div>
   )
}

function Reserve({ modalState, closeModal, setPopUp, countries, data }) {
   const [loading, setLoading] = useState(false)
   const [dob, setDOB] = useState(null)
   const [documents, setDocuments] = useState(false)
   const [expiry, setExpiry] = useState(null)
   const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm()

   // const checkInputs = () => {
   //    return (
   //       errors.mail ||
   //       errors.telephone ||
   //       errors.first_name ||
   //       errors.last_name ||
   //       errors.pob ||
   //       errors.dob ||
   //       errors.nationality ||
   //       !dob ||
   //       !expiry
   //    )
   // }
   const changeDate = (dat) => {
      // console.log(date)
      const specificDate = new Date(dat)

      const year = specificDate.getFullYear()
      const month = (specificDate.getMonth() + 1).toString().padStart(2, '0')
      const day = specificDate.getDate().toString().padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}`
      setDOB(formattedDate)
   }
   // const fileUp = (dat) => {
   //    console.log(dat.target.value)
   // }
   const changeExpDate = (dat) => {
      // console.log(date)
      const specificDate = new Date(dat)

      const year = specificDate.getFullYear()
      const month = (specificDate.getMonth() + 1).toString().padStart(2, '0')
      const day = specificDate.getDate().toString().padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}`
      setExpiry(formattedDate)
   }

   const postData = (formData) => {
      console.log(formData, dob, expiry, documents.file)
      // console.log(formData)

      setLoading(true)
      // setLoading(false)
      const dataToPost = new FormData()
      dataToPost.append('add_inscription', '')
      dataToPost.append('first_name', formData.first_name)
      dataToPost.append('last_name', formData.last_name)
      dataToPost.append('sex', formData.sex)
      dataToPost.append('pob', formData.pob)
      dataToPost.append('dob', dob)
      dataToPost.append('nationality', formData.nationality)
      dataToPost.append('passeport_number', formData.passeport_number)
      dataToPost.append('expiry_date', expiry)
      dataToPost.append('id_faculty', formData.id_faculty)
      dataToPost.append('id_university', data.id_university)
      // dataToPost.append('documents', formData.name)
      dataToPost.append('email', formData.mail)
      dataToPost.append('telephone', formData.telephone)
      dataToPost.append('documents', documents.file)

      // console.log(dataToPost.values())

      axios
         .post(
            'https://admin.okapitouristiques.com/app/api/v1/university/',
            dataToPost,
            {
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
            },
         )
         .then((r) => {
            setPopUp({
               title: 'Notification',
               content: 'Inscription envoyé avec success ✅',
               accept: {
                  title: 'OK',
                  handler: () => {
                     window.history.back()
                     reset()
                  },
               },
            })
            setLoading(false)
         })
         .catch((er) => {
            setPopUp({
               title: '‼️ Notification',
               content:
                  "Oups, Une erreur s'ait produite veuillez réessayer plus tard.",
               decline: {
                  title: 'OK',
                  // handler: close,
               },
            })
            setLoading(false)
         })
   }

   return (
      <Modal
         size="6xl"
         show={modalState}
         onClose={closeModal}
         popup
         dismissible={true}
      >
         <Modal.Header>
            <div className="flex w-full flex-row items-center justify-between gap-28 px-2 font-bold sm:gap-32 md:gap-60 lg:gap-60 xl:gap-72">
               Formulaire D'inscription
            </div>
         </Modal.Header>
         <Modal.Body>
            <div className="flex w-full flex-col items-center gap-2 px-4 pb-4 md:px-6 md:pb-6 ">
               <div className="flex w-full flex-col">
                  <span className="dark:text-darktext mt-2 px-2 text-center text-4 font-bold text-dark-400 md:text-5">
                     Vous pouvez envoyer votre réservation via le formulaire
                     ci-dessous.
                  </span>
               </div>
               <div className="flex w-full  flex-col items-center justify-center gap-2 pb-3 md:w-8/12">
                  <div className="flex w-full flex-col gap-2 sm:gap-3">
                     <div className="flex w-full flex-col items-end justify-center gap-2 md:flex-row md:gap-2">
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Choisir une Faculté"
                           />
                           <Select
                              {...register('id_faculty', { required: true })}
                              id="id_faculty"
                              color={errors.id_faculty && 'failure'}
                              required
                           >
                              {data?.faculties?.map(
                                 ({ id_faculty, faculty_name, program }) => (
                                    <option
                                       selected
                                       value={id_faculty}
                                       key={id_faculty + ' fac'}
                                    >
                                       {faculty_name} - {program}
                                    </option>
                                 ),
                              )}
                           </Select>
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-end justify-center gap-2 md:flex-row md:gap-2">
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Numero de la Piece D'identité"
                           />
                           <TextInput
                              className="w-full"
                              // rightIcon={HiUser}
                              {...register('first_name', { required: true })}
                              id="first_name"
                              color={errors.first_name && 'failure'}
                              type="text"
                              placeholder="Votre Nom et Post-Nom"
                              required
                           />
                        </div>
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Numero de la Piece D'identité"
                           />
                           <TextInput
                              className="w-full"
                              // rightIcon={HiUser}
                              {...register('last_name', { required: true })}
                              id="last_name"
                              color={errors.last_name && 'failure'}
                              type="text"
                              placeholder="Votre Prenom"
                              required
                           />
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-end justify-center gap-2 md:flex-row md:gap-2">
                        {/* <TextInput
                           className="w-full"
                           // rightIcon={HiUser}
                           {...register('prenom', { required: true })}
                           id="prenom"
                           color={errors.prenom && 'failure'}
                           type="text"
                           placeholder="Votre Prenom"
                           required
                        /> */}
                        <div className="flex w-full flex-col">
                           <Label htmlFor="multiple-file-upload" value="Sexe" />
                           <Select
                              className="w-full"
                              {...register('sex', { required: true })}
                              id="sex"
                              color={errors.sex && 'failure'}
                              required
                           >
                              <option value={'FEMININ'}>FEMININ</option>
                              <option value={'MASCULIN'}>MASCULIN</option>
                           </Select>
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-end justify-center gap-2 md:flex-row md:gap-2">
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Date de Naissance"
                           />
                           <Datepicker
                              className="w-full"
                              onSelectedDateChanged={changeDate}
                              maxDate={new Date()}
                              language="fr-FR"
                              // {...register('dob', {
                              //    required: true,
                              // })}
                           />
                        </div>
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Lieu de Naissance"
                           />
                           <TextInput
                              className="w-full"
                              // rightIcon={HiUser}
                              {...register('pob', { required: true })}
                              id="pob"
                              color={errors.pob && 'failure'}
                              type="text"
                              placeholder="Lieu de Naissance"
                              required
                           />
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-end justify-center gap-2 md:flex-row md:gap-2">
                        <div className="flex w-full flex-row gap-1">
                           <div className="flex w-full flex-col">
                              <Label
                                 htmlFor="multiple-file-upload"
                                 value="Nationalité (Pays)"
                              />
                              <Select
                                 {...register('nationality', {
                                    required: true,
                                 })}
                                 id="nationality"
                                 color={errors.nationality && 'failure'}
                                 required
                              >
                                 <option
                                    defaultValue={'CONGO DRC'}
                                    selected
                                    disabled
                                 >
                                    Votre pays d'origine
                                 </option>
                                 {countries?.map(({ name }) => (
                                    <option value={name} key={name + 'serve'}>
                                       {name}
                                    </option>
                                 ))}
                              </Select>
                           </div>

                           {!countries && (
                              <Spinner
                                 color="success"
                                 className="flex w-full items-center justify-center"
                              />
                           )}
                        </div>
                        {/* <div className="flex flex-col w-full">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Type de Piece D'Identité"
                           />
                           <Select
                              {...register('piece_identite', {
                                 required: true,
                              })}
                              id="piece_identite"
                              color={errors.piece_identite && 'failure'}
                              required
                           >
                              <option value={'PASSPORT'}>PASSPORT</option>
                              <option selected value={"CARTE D'ELECTEUR"}>
                                 CARTE D'ELECTEUR
                              </option>
                              <option value={"CARTE NATIONALE D'IDENTITE"}>
                                 CARTE NATIONALE D'IDENTITE
                              </option>
                           </Select>
                        </div> */}
                     </div>
                     <div className="flex w-full flex-col items-end justify-center gap-2 md:flex-row md:gap-2">
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Numero de la Piece D'identité"
                           />
                           <TextInput
                              className="w-full"
                              // rightIcon={HiUser}
                              {...register('passeport_number', {
                                 // required: true,
                              })}
                              id="passeport_number"
                              color={errors.passeport_number && 'failure'}
                              type="text"
                              placeholder="Numero de la Piece D'identité"
                              required
                           />
                        </div>
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Date d'Expiration de la piece d'identité"
                           />
                           <Datepicker
                              onSelectedDateChanged={changeExpDate}
                              minDate={new Date()}
                              language="fr-FR"
                           />
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-end justify-center gap-2 md:flex-row md:gap-2">
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Votre adresse email"
                           />
                           <TextInput
                              className="w-full"
                              rightIcon={HiMail}
                              {...register('mail', {
                                 required: true,
                                 pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              })}
                              id="mail"
                              type="email"
                              aria-invalid={errors.mail ? 'true' : 'false'}
                              color={errors.mail && 'failure'}
                              placeholder="Votre adresse email"
                              required
                           />
                        </div>
                        {/* {errors.mail && <p role="alert">{errors.mail.message}</p>} */}
                        <div className="flex w-full flex-col">
                           <Label
                              htmlFor="multiple-file-upload"
                              value="Votre Numero de telephone"
                           />
                           <TextInput
                              className="w-full"
                              rightIcon={HiPhone}
                              {...register('telephone', {
                                 required: true,
                                 pattern: /^\+?\d[\d\s\.\-]+$/,
                              })}
                              id="number"
                              type="tel"
                              placeholder="Votre Numero de telephone"
                              color={errors.telephone && 'failure'}
                              required
                           />
                        </div>
                     </div>
                     <div className="flex w-full flex-col">
                        <Label
                           htmlFor="multiple-file-upload"
                           value="Votre Dossier"
                        />
                        <File
                           value={documents}
                           sendValue={setDocuments}
                           only={'.pdf,.doc,.docx'}
                        />
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2">
                     <Button
                        isProcessing={loading}
                        disabled={(!dob && !documents) || loading}
                        onClick={handleSubmit((dd) => {
                           postData(dd)
                        })}
                        className="w-full bg-primary hover:bg-dark"
                     >
                        Envoyer la Demande
                     </Button>
                  </div>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}
