import { Link } from 'react-router-dom'
import Error404 from '../../assets/svg/404.svg'
// import { Header } from '../App/Login'

function NotFound() {
   return (
      <div className="h-full w-full max-w-screen-2xl p-4">
         {/* <Header /> */}
         <div className="mt-20 flex flex-col items-center justify-center">
            <img src={Error404} alt="" srcset="" />
            <Link to={'/'} className="font-semibold text-primary">
               Retour
            </Link>
         </div>
      </div>
   )
}

export default NotFound
