// import BlogCard from '../../components/BlogCard'
import { Link, Route, Routes, useParams } from 'react-router-dom'
// import FormationDetails from '../../components/FormationDetails'
import { Breadcrumb, Spinner } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { HiHome } from 'react-icons/hi'
import { TextBanner } from '../../pages/Home'
import { MyLoader } from '../Section'
import TourDetails from '../TourDetails'
import TourView from '../TourView'

export default function DestinationDetails({ destinations }) {
   const params = useParams()
   const [dest, setDest] = useState()
   window.scrollTo(0, 0)

   useEffect(() => {
      destinations &&
         destinations.length > 0 &&
         setDest(
            destinations.find(
               (e) => Number(e.id_destination) === Number(params.id),
            ),
         )
   }, [params, destinations])

   // console.log(params.id)

   return (
      <div className="bg- mb-16 flex h-full  w-full flex-col items-center">
         <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
            {dest ? (
               <Routes>
                  <Route path="/" element={<Tours destination={dest} />} />
                  <Route
                     path="/:id"
                     element={
                        <TourDetails
                           tours={dest && dest.sites}
                           destination={dest}
                        />
                     }
                  />
               </Routes>
            ) : (
               <MyLoader />
            )}
         </div>
      </div>
   )
}

function Tours({ destination }) {
   const params = useParams()
   return (
      <>
         <Breadcrumb
            aria-label="Default breadcrumb example"
            className=" w-full px-2 md:px-5"
         >
            <Breadcrumb.Item icon={HiHome}>
               <Link to={'/'} className="text-dark hover:font-bold">
                  Home
               </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
               <Link to={'/destinations'} className="text-dark hover:font-bold">
                  Destinations
               </Link>
            </Breadcrumb.Item>
            {params.id && <Breadcrumb.Item>{params.id}</Breadcrumb.Item>}
         </Breadcrumb>
         {destination ? (
            <div className=" flex h-full w-full flex-col gap-6 rounded-2xl p-2 md:p-5">
               <div>
                  <span className="rounded-xl bg-white px-4 py-2 text-4 font-semibold shadow-xs">
                     🚀 Visit {destination.destination_name}
                  </span>
               </div>
               <div className="grid w-full grid-cols-1 items-center gap-4  md:flex-row">
                  {destination.sites ? (
                     destination.sites.map((tour) => (
                        <TourView
                           destination={destination}
                           tour={tour}
                           to={tour.site_id}
                           key={tour.site_id + tour.id_destination}
                        />
                     ))
                  ) : (
                     <Spinner
                        aria-label="Extra large spinner example"
                        size="xl"
                     />
                  )}
               </div>
            </div>
         ) : (
            <MyLoader />
         )}
         <TextBanner />
      </>
   )
}
