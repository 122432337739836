import React from 'react'
// import './styles/fa.min'
import 'material-icons/iconfont/round.css'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NotFound from './pages/404'
import Blog from './pages/Blog'
import WebsiteHome from './pages/Home'
import './styles/index.css'
import {
   CartProvider,
   CategoryModalProvider,
   DestinationProvider,
   EventModalProvider,
   ExploreModalProvider,
   FormModalProvider,
   NavProvider,
   PopUpProvider,
   SessionProvider,
} from './utilis/Context'

import NavBar from './components/NavBar'
import reportWebVitals from './reportWebVitals'
// import Formation from './pages/Formation'
import Footer from './components/Footer'
// import Library from './pages/Library'
// import EventDetails from './components/EventDetails'
// import Categories from './components/Categories'
// import Explore from './components/Explore'
// import Dates from './components/Dates'
// import Login from './components/Login'
import PopUp from './components/PopUp'
// import { Card } from 'flowbite-react'
// import Cart from './components/Cart'
// import Tours from './pages/Tours'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Destinations from './pages/Destinations'
import Inscription from './pages/Inscription'
import Library from './pages/Library'
import OurServices from './pages/OurServices'
// import ErrorBoundary from './utilis/ErrorBoundary'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
   <React.StrictMode>
      {/* <ErrorBoundary> */}
      <SessionProvider>
         <NavProvider>
            <EventModalProvider>
               <CategoryModalProvider>
                  <ExploreModalProvider>
                     <FormModalProvider>
                        <PopUpProvider>
                           <CartProvider>
                              <DestinationProvider>
                                 <Router>
                                    <div className="dark:bg-dark-500 flex h-full min-h-screen w-full flex-col items-center gap-2 bg-darktext-white md:p-2 md-max:pt-2">
                                       <div class="gtranslate_wrapper px-2"></div>
                                       {/* Website */}
                                       {/* <Dashbord> */}
                                       <NavBar />
                                       <Routes>
                                          <Route
                                             path="/"
                                             element={<WebsiteHome />}
                                          />
                                          <Route
                                             path="/destinations/*"
                                             element={<Destinations />}
                                          />
                                          <Route
                                             path="/about-us"
                                             element={<AboutUs />}
                                          />
                                          <Route
                                             path="/blog/*"
                                             element={<Blog />}
                                          />
                                          <Route
                                             path="/contact-us"
                                             element={<ContactUs />}
                                          />
                                          <Route
                                             path="/services"
                                             element={<OurServices />}
                                          />
                                          <Route
                                             path="/library/*"
                                             element={<Library />}
                                          />
                                          <Route
                                             path="/inscriptions/*"
                                             element={<Inscription />}
                                          />
                                          <Route
                                             path="*"
                                             element={<NotFound />}
                                          />
                                       </Routes>
                                       {/* <Cart /> */}
                                       {/* <Login /> */}
                                       {/* <EventDetails /> */}
                                       {/* <Categories /> */}
                                       <Footer />
                                       {/* <Explore /> */}
                                       {/* <Dates /> */}
                                       <PopUp />
                                       {/* </Dashbord> */}
                                    </div>
                                 </Router>
                              </DestinationProvider>
                           </CartProvider>
                        </PopUpProvider>
                     </FormModalProvider>
                  </ExploreModalProvider>
               </CategoryModalProvider>
            </EventModalProvider>
         </NavProvider>
      </SessionProvider>
      {/* </ErrorBoundary> */}
   </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
