import { useEffect, useState } from 'react'
import { HiUser, HiUserAdd, HiUserRemove } from 'react-icons/hi'

export default function People({ name, max, sendValue }) {
   const [people, setPeople] = useState(1)

   const add = () => {
      people >= 0 && !max && setPeople(people + 1)
   }
   const remove = () => {
      people > 0 && setPeople(people - 1)
   }
   const handleChange = (e) => {
      Number(e.target.value) > 0 ? setPeople(e.target.value) : setPeople(people)
   }

   useEffect(() => {
      sendValue(people)
   }, [people])

   return (
      <div className="flex flex-row items-center justify-between rounded-md border p-2">
         <HiUser className="material-icons-round dark:text-darktext dark:bg-dark rounded-full p-1  text-8 text-[#585E6D]" />
         {/* <span className="material-icons-round text-[#585E6D] rounded-full p-1  dark:text-darktext dark:bg-dark ">
            people
         </span> */}
         <span className="font dark:text-darktext text-dark-400">
            Nombre d'{name} :
         </span>
         <div className="flex flex-row items-center gap-2">
            <HiUserRemove
               onClick={() => remove()}
               className=" material-icons-round dark:text-darktext dark:bg-dark cursor-pointer rounded-md bg-dark-400 p-1 text-6 text-white "
            />
            {/* <span
               onClick={() => remove()}
               className="cursor-pointer material-icons-round bg-dark-400 rounded-md p-1 text-4 text-white dark:text-darktext dark:bg-dark "
            >
               remove
            </span> */}
            <input
               type="numberr"
               onChange={(e) => handleChange(e)}
               value={people}
               className="dark:text-darktext max-w-8 border-none bg-inherit p-1 text-center font-semibold text-dark-400"
            />
            <HiUserAdd
               onClick={() => add()}
               className=" material-icons-round dark:text-darktext dark:bg-dark cursor-pointer rounded-md bg-primary p-1 text-6 text-white "
            />
            {/* <span
               onClick={() => add()}
               className="cursor-pointer material-icons-round bg-primary rounded-md p-1 text-4 text-white dark:text-darktext dark:bg-dark "
            >
               add
            </span> */}
         </div>
      </div>
   )
}
