// import 'https://cdn.gtranslate.net/widgets/latest/flags.js'
import { Sidebar } from 'flowbite-react'
import { useEffect, useState } from 'react'
import {
   HiBookOpen,
   HiBriefcase,
   // HiHand,
   HiHome,
   HiMail,
   HiMap,
   HiOfficeBuilding,
   HiOutlineTicket,
   HiPhone,
} from 'react-icons/hi'
import { Link } from 'react-router-dom'
import logo from '../../assets/icons/okapi.png'
import { DestinationContext, FormModalContext } from '../../utilis/Context'
import Nav from '../Nav'
import NavIcon from '../NavIcon'
// import {
//    HiArrowSmRight,
//    HiChartPie,
//    HiInbox,
//    HiShoppingBag,
//    HiTable,
//    HiUser,
//    HiViewBoards,
// } from 'react-icons/hi'
// import { GiHamburgerMenu } from 'react-icons/gi'
export default function NavBar() {
   // const handleShowMenu = (title) => {
   //    SetShowMenu(!showMenu)
   //    // console.log(active)
   // }

   const [showMenu, SetShowMenu] = useState(false)

   // useEffect(()=>{
   //    console.log(active)
   // },[active])

   return (
      <FormModalContext.Consumer>
         {({ form, changeForm }) => (
            <div className="z-10 h-10 w-full items-center  justify-center md:h-14 md:max-w-screen-xl">
               <TopNavBar />
               <div className="dark:border-none dark:bg-dark-400 fixed left-0 top-10  hidden h-20 w-full items-center justify-center border-b bg-white md:flex">
                  <div className="flex w-full max-w-screen-xl items-center justify-between">
                     <div className="flex flex-row items-center justify-between gap-10">
                        <div className="flex h-full  items-center justify-start px-4">
                           <Link
                              to="/"
                              className="dark:text-white text-5 font-semibold hover:text-primary"
                           >
                              <img src={logo} alt="logo" className="h-24" />
                           </Link>
                        </div>
                        <div className="flex h-full flex-row items-center justify-start gap-10  rounded-br-2xl">
                           <Nav
                              title={'Acceuil'}
                              link={'/'}
                              icon="home"
                              HiCon={HiHome}
                           />
                           <Nav
                              title={'Destinations'}
                              link={'/destinations/'}
                              icon="connecting_airports"
                              HiCon={HiMap}
                           />
                           <DestinationContext.Consumer>
                              {({ articles }) =>
                                 articles &&
                                 articles.length > 0 && (
                                    <Nav
                                       title={'Blog'}
                                       link={'/blog'}
                                       icon="payments"
                                       HiCon={HiBookOpen}
                                    />
                                 )
                              }
                           </DestinationContext.Consumer>
                           <Nav
                              title={'Nos Services'}
                              link={'/services'}
                              icon="payments"
                              HiCon={HiBriefcase}
                           />
                           <Nav
                              title={'Apropos de nous'}
                              link={'/about-us'}
                              icon="payments"
                              HiCon={HiOfficeBuilding}
                           />
                           {/* <Nav
                              title={'Contact Us'}
                              icon="chat"
                              link={'/contact-us'}
                              HiCon={HiChat}
                           /> */}
                        </div>
                     </div>
                     {/* <SessionContext.Consumer>
                        {({ loggedUser }) => ( */}
                     <div className="flex h-full items-center justify-center gap-2 px-4">
                        {/* <Link
                                 to="/"
                                 onClick={() => changeForm('notifications')}
                                 className="hover:text-primary dark:text-slate-400 dark:hover:text-white text-8 material-icons-round rounded-full"
                              >
                                 notifications
                              </Link>
                              <Link
                                 onClick={() => changeForm('profile')}
                                 className="hover:text-primary dark:text-slate-400 dark:hover:text-white text-8 material-icons-round rounded-full"
                              >
                                 person
                              </Link> */}
                        {/* {!loggedUser && ( */}
                        <Link
                           // onClick={() => changeForm('login')}
                           to="destinations"
                           className="dark:bg-darktext dark:hover:text-primary dark:text-dark rounded-full bg-primary px-4 py-2 font-semibold text-darktext  hover:text-darktext-white"
                        >
                           Réserver
                        </Link>
                        {/* )} */}
                     </div>
                     {/* )} */}
                     {/* </SessionContext.Consumer> */}
                  </div>
               </div>
               <div className="dark:border-none fixed left-0 top-0 flex w-full max-w-screen-xl items-center justify-between border-b md:hidden">
                  <div className="dark:bg-dark-400 flex w-full flex-row items-center justify-between bg-white py-2 pl-4 pr-2">
                     <Link
                        to="/"
                        className="dark:text-white flex items-center text-6 font-semibold hover:text-primary"
                     >
                        <>
                           <img src={logo} alt="logo" className="h-10" />

                           <span className="text-red-500">Okapi</span>
                           <span className="text-primary">Touristique</span>
                        </>
                     </Link>
                     <div className="flex items-center gap-2">
                        {/* <SessionContext.Consumer>
                           {({ loggedUser }) => (
                              <Link
                                 onClick={() => changeForm('login')}
                                 className="hover:text-darktext-white dark:bg-darktext dark:hover:text-primary bg-primary px-2 py-1 font-semibold text-darktext dark:text-primary  rounded-full"
                              >
                                 Sign Up
                              </Link>
                           )}
                        </SessionContext.Consumer> */}
                        <Link
                           onClick={() => SetShowMenu(true)}
                           className="dark:hover:text-white dark:text-slate-400 material-icons-round rounded-full text-8 text-dark hover:text-primary"
                        >
                           menu
                        </Link>
                     </div>

                     {showMenu && <Menu handler={SetShowMenu} />}
                  </div>
               </div>
               <div className="bottom-0 left-0 hidden  h-14  w-screen max-w-screen-xl items-center justify-center shadow-xs md:hidden ">
                  <div className="dark:border-none dark:bg-dark-400 runded-t-2xl flex h-full w-full flex-row items-center justify-around border-t bg-white p-4 py-2 pl-4 pr-2">
                     <NavIcon link="/" name="event" title="Acceuil" />
                     <NavIcon link="/" name="explore" title="Tours" />
                     <NavIcon
                        onClick={() => changeForm('my tickets')}
                        link="/formations"
                        HiCon={HiOutlineTicket}
                        name="my tickets"
                        title="About Us"
                     />
                     {/* <NavIcon link="/library" name="receipt" title="Livres" /> */}
                     <NavIcon
                        onClick={() => changeForm('profile')}
                        link="/"
                        name="person"
                        title="Profile"
                     />
                  </div>
               </div>
            </div>
         )}
      </FormModalContext.Consumer>
   )
}

function Menu({ handler }) {
   const [script, setScript] = useState()
   const [x, setX] = useState(0)
   // window.gtranslateSettings = {
   //    default_language: 'fr',
   //    languages: ['fr', 'ar', 'es', 'zh-CN'],
   //    wrapper_selector: '.gtranslate_wrapper',
   //    flag_size: 24,
   //    alt_flags: { en: 'usa' },
   // }

   // useEffect(() => {
   const dropTrans = () => {
      setScript(document.createElement('script'))

      if (script)
         script.src = 'https://cdn.gtranslate.net/widgets/latest/float.js'
      if (script) script.async = true

      // // Append the script to the document body
      if (script) {
         document.body.appendChild(script)
         setX(1)
      }
   }

   window.gtranslateSettings = {
      default_language: 'fr',
      languages: ['fr', 'en'],
      wrapper_selector: '.gtranslate_wrapper',
      flag_size: 24,
      alt_flags: { en: 'usa' },
   }
   useEffect(() => {
      x !== 1 && dropTrans()

      // return document.body.remove(script)
   }, [script])

   return (
      <Sidebar
         aria-label="Default sidebar example"
         className="fixed right-0 top-0 bg-primary"
      >
         <div className="px- flex w-full justify-end">
            <span
               onClick={() => handler(false)}
               className="material-icons-round cursor-pointer text-7 font-semibold"
            >
               close
            </span>
         </div>
         <Sidebar.Items className="">
            <Sidebar.ItemGroup>
               <Sidebar.Item
                  icon={HiHome}
                  className="hover:font-semibold hover:text-primary"
               >
                  <Link onClick={() => handler(false)} to={'/'}>
                     Acceuil
                  </Link>
               </Sidebar.Item>
               {/* <Sidebar.Item
                  href="#"
                  icon={HiViewBoards}
                  label="Pro"
                  labelColor="dark"
               >
                  Kanban
               </Sidebar.Item> */}
               <Sidebar.Item
                  icon={HiMap}
                  className="hover:font-semibold hover:text-primary"
               >
                  <Link onClick={() => handler(false)} to={'/destinations'}>
                     Destinations
                  </Link>
               </Sidebar.Item>
               <DestinationContext.Consumer>
                  {({ articles }) =>
                     articles &&
                     articles.length > 0 && (
                        <Sidebar.Item
                           icon={HiBookOpen}
                           className="hover:font-semibold hover:text-primary"
                        >
                           <Link onClick={() => handler(false)} to={'/blog'}>
                              Blog
                           </Link>
                        </Sidebar.Item>
                     )
                  }
               </DestinationContext.Consumer>

               <Sidebar.Item
                  icon={HiBriefcase}
                  className="hover:font-semibold hover:text-primary"
               >
                  <Link onClick={() => handler(false)} to={'/services'}>
                     Nos Services
                  </Link>
               </Sidebar.Item>
               <Sidebar.Item
                  icon={HiBookOpen}
                  className="hover:font-semibold hover:text-primary"
               >
                  <Link onClick={() => handler(false)} to={'/inscriptions'}>
                     Demande Inscription
                  </Link>
               </Sidebar.Item>
               <Sidebar.Item
                  icon={HiOfficeBuilding}
                  className="hover:font-semibold hover:text-primary"
               >
                  <Link onClick={() => handler(false)} to={'/about-us'}>
                     Appropos de nous
                  </Link>
               </Sidebar.Item>
               {/* <Sidebar.Item
                  icon={HiChat}
                  className="hover:font-semibold hover:text-primary"
               >
                  <Link onClick={() => handler(false)} to={'/contact-us'}>
                     Nous Contacter
                  </Link>
               </Sidebar.Item> */}
               {/* <Sidebar.Item href="#" icon={HiTable}>
                  Sign Up
               </Sidebar.Item> */}
            </Sidebar.ItemGroup>
         </Sidebar.Items>
      </Sidebar>
   )
}

function TopNavBar() {
   const [script, setScript] = useState()
   const [x, setX] = useState(0)
   // window.gtranslateSettings = {
   //    default_language: 'fr',
   //    languages: ['fr', 'ar', 'es', 'zh-CN'],
   //    wrapper_selector: '.gtranslate_wrapper',
   //    flag_size: 24,
   //    alt_flags: { en: 'usa' },
   // }

   // useEffect(() => {
   const dropTrans = () => {
      setScript(document.createElement('script'))

      if (script)
         script.src = 'https://cdn.gtranslate.net/widgets/latest/float.js'
      if (script) script.async = true

      // // Append the script to the document body
      if (script) {
         document.body.appendChild(script)
         setX(1)
      }
   }

   const remove = () => {
      script && document.body.remove(script)
      script && setX(0)
   }

   window.gtranslateSettings = {
      default_language: 'fr',
      languages: ['fr', 'en', 'es', 'ar', 'zh-CN'],
      wrapper_selector: '.gtranslate_wrapper',
      flag_size: 24,
      alt_flags: { en: 'usa' },
   }
   useEffect(() => {
      x !== 1 && dropTrans()

      // return script && remove()
   }, [script])
   // }, [])

   return (
      <>
         <div className="dark:border-none dark:bg-dark-400 fixed left-0 top-0  hidden h-10 w-full flex-row items-center justify-center gap-8 border-b bg-primary md:flex">
            <div className="flex w-full max-w-screen-xl flex-row items-center justify-end gap-2 pr-14">
               <div className="flex flex-row items-center gap-2">
                  {/* <span className="material-icons-round text-white">phone</span> */}
                  <HiPhone className="text-white" />
                  <a href="tel:+243976388356" className=" text-white">
                     (+243) 976 388 356
                  </a>
               </div>
               <div className="flex flex-row items-center gap-2">
                  {/* <span className="material-icons-round text-white">mail</span> */}
                  <HiMail className="text-white" />
                  <a
                     href="mailto:contacts@okapitouristiques.com"
                     className=" text-white"
                  >
                     okapitouristiqueofficiel@gmail.com
                  </a>
               </div>
               <div class="gtranslate_wrapper"></div>
            </div>
         </div>
         {/* <script defer src="https://cdn.gtranslate.net/widgets/latest/flags.js"></script> */}
      </>
   )
}
