// import BlogCard from '../../components/BlogCard'
import { Route, Routes } from 'react-router-dom'
// import FormationDetails from '../../components/FormationDetails'
import BookCard from '../../components/BookCard'
import BookDetails from '../../components/BookDetails'

export default function Library() {
   // const youtube = '<iframe width="715" height="402" src="https://www.youtube.com/embed/ex3C1-5Dhb8" title="The Creator | Official Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
   const data = [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
   ]
   return (
      <div className="bg- mb-16 flex h-full  w-full flex-col items-center">
         <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
            <Routes>
               <Route path="/" element={<Blogs data={data} />} />
               <Route path="/:id" element={<BookDetails />} />
            </Routes>
         </div>
      </div>
   )
}

function Blogs({ data }) {
   return (
      <div className=" flex h-full w-full flex-col gap-4 rounded-2xl border p-5">
         <div>
            <span className="rounded-xl bg-white px-2 py-1 text-4 font-semibold shadow-xs">
               📚 Livres
            </span>
         </div>
         <div className="grid w-full grid-cols-1 items-center gap-4 md:grid-cols-2  md:flex-row">
            {data.map(({ id }) => (
               <BookCard to={id} key={id} />
            ))}
         </div>
      </div>
   )
}
