// import './index.css'
// import NavBar from '../../components/NavBar'
import HomeBanner from '../../components/HomeBanner'
import Posts from '../../components/Posts'
import Section from '../../components/Section'
// import img from '../../assets/imgs/music.jpeg'
import { HiArrowCircleRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import logo from '../../assets/icons/okapi.png'
import Services from '../../components/Services'

// import Footer from '../../components/Footer'

export default function Home() {
   document.title = 'Okapi Touristiques - Acceuil'
   return (
      <div className="flex h-full w-full max-w-screen-xl  flex-col items-center md:mt-16">
         <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
            {/* <SearchBar/> */}
            <HomeBanner />
            <div className="dark:bg-dark-400 flex w-full flex-col rounded-xl bg-white md:p-5">
               {/* <video
                  className="w-full"
                  loop
                  muted
                  src="https://okapitouristiques.com/assets/video/into.mp4"
                  alt="video intro"
               ></video> */}
               <Section title="🌍 Nos Destinations" />
            </div>
            <Services />
            <TextBanner
               img={logo}
               link="/about-us"
               btn="En Savoir plus.."
               title={'Nous connaitre'}
               text={`Nous sommes une Société anonyme de droit Congolais (S.A), créée depuis 2018 par le numéro national d’identification 01-F4300-N77201H en application de l’esprit de l’ordonnance n° 73/236 du 13 août 1973 portant institution des numéros nationaux d’identification,`}
            />
            <div className="dark:bg-dark-400 flex w-full flex-col gap-5 rounded-xl bg-white p-4 md:p-5">
               <Posts title={'🚀 Top Sites'} />
            </div>
            {/* <TextBanner title={'Free Cancelation'} /> */}
         </div>
      </div>
   )
}

export function TextBanner({ title, text, img, link, btn }) {
   return (
      <div className="flex min-h-60 w-full flex-col items-center justify-around bg-secondary p-5 md:flex-row md:rounded-xl">
         {img && (
            <div className="flex h-60 w-60 flex-row  items-center justify-center rounded-full p-2 shadow-xs ">
               <img src={img} alt="img" srcset="" className="h-full" />
            </div>
         )}
         <div className="flex min-h-60 flex-col items-center justify-center bg-secondary p-4 md:w-6/12 md:rounded-xl">
            <span className=" text-center text-6 font-bold italic text-dark md:text-10">
               {title ? title : 'Gardez les choses flexibles'}
            </span>
            <span className="text-center font-bold text-dark">
               {text
                  ? text
                  : 'Réservez maintenant et payez plus tard pour sécuriser les activités que vous ne voulez pas manquer sans être bloqué.'}
            </span>
            {btn && link && (
               <Link
                  to={link}
                  className=" mt-4 flex cursor-pointer gap-1 rounded bg-primary py-2 pl-4 pr-2 font-semibold text-darktext hover:bg-green-600"
               >
                  <span>{btn}</span>
                  <HiArrowCircleRight className="text-5" />
               </Link>
            )}
         </div>
      </div>
   )
}
