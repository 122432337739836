import { Spinner } from 'flowbite-react'
import { DestinationContext } from '../../utilis/Context'
import SectionCard from '../SectionCard'

export default function Section({ title }) {
   // const [data, setData] = useState()

   // useEffect(() => {
   //    fetchData()
   // }, [])

   // const fetchData = () => {
   //    axios
   //       .get('https://okapitouristiques.com/app/api/v1/destinations')
   //       .then((e) => setData(e.data))
   //       .catch((r) => console.error(r))
   // }

   // const data = events

   return (
      <DestinationContext.Consumer>
         {({ destinations }) => (
            <div className="dark:bg-dark-400 hide-scroll flex h-auto w-auto flex-col gap-2 overflow-scroll px-2 sm:pt-4 md:w-full md:gap-4 md:px-6">
               <div className="mb-1 flex flex-col items-center  justify-center gap-2">
                  <div class="w-full px-4">
                     <div class="mx-auto mb-2 max-w-[510px] text-center lg:mb-5">
                        <span class="mb-2 block text-lg font-semibold text-primary">
                           Destinations
                        </span>
                        <h2 class="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                           {title}
                        </h2>
                        {/* <p class="text-base text-body-color">
                           There are many variations of passages of Lorem Ipsum
                           available but the majority have suffered alteration
                           in some form.
                        </p> */}
                     </div>
                  </div>
               </div>
               <div className="flex min-h-60 w-auto flex-row items-center gap-5 overflow-scroll pb-4 md:w-full md:flex-wrap md:justify-center">
                  {destinations && destinations.length > 0 ? (
                     destinations.map((event) => (
                        <SectionCard event={event} key={event.id_destination} />
                     ))
                  ) : (
                     <MyLoader />
                  )}
               </div>
            </div>
         )}
      </DestinationContext.Consumer>
   )
}

export function MyLoader() {
   return (
      <div className="flex h-full w-full flex-col items-center justify-center p-8">
         <Spinner
            color="success"
            aria-label="Extra large spinner example"
            size="xl"
         />
      </div>
   )
}
