import { FaFacebook, FaLink, FaTwitter, FaWhatsapp } from 'react-icons/fa'
// import { useState } from "react";
export default function ShareButtons({
   facebook = true,
   whatsapp = true,
   twitter = true,
   link = true,
   className,
}) {
   // const
   // const handleClick = () => {
   //     handler(title)
   // }

   return (
      <div className={'flex w-full flex-row items-center ' + className}>
         <div className="flex w-full flex-row items-center justify-center gap-2 rounded bg-primary-500 p-1">
            {facebook && <FacebookButton className="text-6 md:text-8" />}
            {whatsapp && <WhatsappButton className="text-6 md:text-8" />}
            {twitter && <TwitterButton className="text-6 md:text-8" />}
            {link && <ShareButton className="text-6 md:text-8" />}
         </div>
      </div>
   )
}

function FacebookButton({ name, icon, className }) {
   const share = () => {
      window.open(
         `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href,
         )}`,
         '_blank',
      )
   }
   return (
      <div
         onClick={share}
         className="cursor-pointer rounded-full p-2 text-white hover:bg-blue-700"
      >
         <FaFacebook className={className + ' '} />
      </div>
   )
}
function WhatsappButton({ name, icon, className }) {
   const share = () => {
      window.open(
         `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${document.title} ${window.location.href}`,
         )}`,
         '_blank',
      )
   }

   return (
      <div
         onClick={share}
         className="cursor-pointer rounded-full p-2  text-white hover:bg-green-600"
      >
         <FaWhatsapp className={className + ' '} />
      </div>
   )
}
function TwitterButton({ name, icon, className }) {
   const share = () => {
      window.open(
         `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            window.location.href,
         )}&text=${encodeURIComponent(document.title)}`,
         '_blank',
      )
   }
   return (
      <div
         onClick={share}
         className="cursor-pointer rounded-full p-2 text-white hover:bg-blue-700"
      >
         <FaTwitter className={className + ' '} />
      </div>
   )
}
function ShareButton({ name, icon, className }) {
   const share = () => {
      window.open(
         `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            window.location.href,
         )}&text=${encodeURIComponent(document.title)}`,
         '_blank',
      )
   }
   return (
      <div
         onClick={share}
         className="cursor-pointer  rounded-full p-2 text-white hover:bg-black"
      >
         <FaLink className={className + ' '} />
      </div>
   )
}
