// import BlogCard from '../../components/BlogCard'
// import FormationDetails from '../../components/FormationDetails'
import BookCard from '../../components/BookCard'
import Contact from '../../components/Contact'
import { PopUpContext } from '../../utilis/Context'

export default function ContactUs() {
   window.scrollTo(0, 0)
   document.title = 'Okapi Touristiques - Contact Us'
   return (
      <PopUpContext.Consumer>
         {({ openPopUp }) => (
            <div className="bg- mb-16 flex h-full  w-full flex-col items-center">
               <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
                  <Contact setPopUp={openPopUp} />
               </div>
            </div>
         )}
      </PopUpContext.Consumer>
   )
}

function Blogs({ data }) {
   return (
      <div className=" flex h-full w-full flex-col gap-4 rounded-2xl border p-5">
         <div>
            <span className="rounded-xl bg-white px-2 py-1 text-4 font-semibold shadow-xs">
               📚 Livres
            </span>
         </div>
         <div className="grid w-full grid-cols-1 items-center gap-4 md:grid-cols-2  md:flex-row">
            {data.map(({ id }) => (
               <BookCard to={id} key={id} />
            ))}
         </div>
      </div>
   )
}
