import axios from 'axios'
import {
   Button,
   Modal,
   Select,
   Spinner,
   Textarea,
   TextInput,
} from 'flowbite-react'
import { useEffect, useState } from 'react'
import {
   HiArrowCircleLeft,
   HiMail,
   HiPhone,
   HiUser,
   HiXCircle,
} from 'react-icons/hi'
import airtel from '../../assets/icons/airtel.png'
import mastercard from '../../assets/icons/mastercard.png'
import mpesa from '../../assets/icons/mpesa.png'
import orange from '../../assets/icons/orange.jpeg'
import visa from '../../assets/icons/visa.jpeg'

import {} from 'flowbite-react'
import { useForm } from 'react-hook-form'
import { PopUpContext } from '../../utilis/Context'
import ErrorBoundary from '../../utilis/ErrorBoundary'
import { sitesAssetLink } from '../SectionCard'
// HiUser

export default function TourBooking({
   tour,
   date,
   people,
   modalState,
   closeModal,
}) {
   const [payment, setPayment] = useState(false)
   const [reserve, setReserve] = useState(false)

   // console.log(date)

   const handleReserve = () => {
      setReserve(true)
      setPayment(true)
   }
   const openPayment = () => {
      setReserve(false)
      setPayment(true)
   }
   const closePayment = () => {
      reserve && setReserve(false)
      payment && setPayment(false)
   }

   return (
      <Modal
         size="4xl"
         show={modalState}
         onClose={closeModal}
         popup
         dismissible={true}
      >
         {/* <Modal.Header className=""> */}
         <div className="flex w-full flex-row items-center justify-between p-2 ">
            {payment ? (
               <HiArrowCircleLeft
                  onClick={closePayment}
                  className="material-icons-round dark:text-darktext dark:hover:bg-dark-400 cursor-pointer justify-end rounded p-1 text-8 text-dark hover:bg-darktext"
               />
            ) : (
               <div></div>
            )}
            <ErrorBoundary>
               <span className="items-center gap-2 text-center font-semibold">
                  {tour.site_name}
               </span>
            </ErrorBoundary>
            <HiXCircle
               onClick={closeModal}
               className="material-icons-round dark:text-darktext dark:hover:bg-dark-400 cursor-pointer justify-end rounded p-1 text-8 text-dark hover:bg-darktext"
            />
         </div>
         {/* </Modal.Header> */}
         <Modal.Body className="dark:bg-dark-400 bottom-0 rounded-xl p-0 sm-max:p-0">
            <div className="dark:bg-dark-400 flex  h-full w-full flex-col items-center rounded-xl bg-white">
               {!payment ? (
                  <div className="flex w-full flex-col items-center gap-2 px-4 pb-2 md:flex-row md:p-4 md:px-6">
                     <div className="flex h-full  w-full  flex-col items-center gap-2 px-4 md:px-6 ">
                        <img
                           src={sitesAssetLink + tour.images[0].img_name}
                           className=" h-10/12 p-2"
                           alt="site image"
                        />
                     </div>
                     <div className="flex w-full  flex-col items-center gap-2 px-4 pb-4 md:px-6 md:pb-6 ">
                        {/* <span className="px-2 mt-2 text-center text-4 text-dark-400 dark:text-darktext">
                           Everything in your cart will be cleared when you
                           leave this page
                        </span> */}
                        {/* {cart.map(({ event, tickets, ticket, id }) => (
                         <BookedTicket
                            id={id}
                            event={event}
                            spots={tickets}
                            ticket={ticket}
                            key={id}
                         />
                      ))} */}
                        <div className="w-full flex-col border-b p-2 pb-4">
                           <div className="flex w-full items-end justify-around   py-2">
                              <span className="font-semibold md:text-5">
                                 {tour.site_name}
                              </span>
                           </div>
                           <div className="flex w-full items-end justify-around   ">
                              <span className="dark:text-darktext text-center text-3 font-light text-dark-400">
                                 {people.adults} Adult x{' '}
                                 {tour.currency ? tour.currency : '$'}
                                 {tour.price}.00 :
                              </span>
                              <span className="dark:text-darktext text-center text-3 font-light text-dark-400">
                                 {tour.currency ? tour.currency : '$'}
                                 {tour.price * people.adults}.00
                              </span>
                           </div>
                           <div className="flex w-full items-end justify-around   ">
                              <span className="dark:text-darktext text-center text-3 font-light text-dark-400">
                                 {people.children} Adult x{' '}
                                 {tour.currency ? tour.currency : '$'}
                                 {tour.price}.00 :
                              </span>
                              <span className="dark:text-darktext text-center text-3 font-light text-dark-400">
                                 {tour.currency ? tour.currency : '$'}
                                 {tour.price * people.children}.00
                              </span>
                           </div>
                           <div className="flex w-full items-end justify-around   ">
                              <span className="dark:text-darktext text-center font-semibold text-dark-400 md:text-4">
                                 Total amount :
                              </span>
                              <span className="dark:text-darktext text-center font-semibold text-dark-400 md:text-4">
                                 {tour.currency ? tour.currency : '$'}
                                 {tour.price * people.children +
                                    tour.price * people.adults}
                                 .00
                              </span>
                           </div>
                        </div>
                        <div className="flex w-full flex-col items-center justify-center gap-2">
                           <Button
                              onClick={() => handleReserve()}
                              color=""
                              className="bg- dark:text-darktext dark:bg-dark w-full border-2 border-primary text-primary"
                           >
                              Réserver Maintenant
                              {/* et Payer Après */}
                           </Button>
                           {/* <Button
                              onClick={() => openPayment()}
                              disabled={10 <= 0}
                              color="blue"
                              className="w-full bg-primary hover:bg-dark"
                           >
                              Passer au Paiement
                           </Button> */}
                        </div>
                     </div>
                  </div>
               ) : (
                  <Payment
                     close={closeModal}
                     data={{
                        tour: tour,
                        people: people,
                        date: date,
                        total: tour.price * people.children,
                     }}
                     reserve={reserve}
                  />
               )}
            </div>
         </Modal.Body>
      </Modal>
   )
}

function Payment({ data, reserve, close }) {
   const [countries, setCountries] = useState(null)
   // console.log(data)

   useEffect(() => {
      ///axios
      axios
         .get('https://restcountries.com/v2/all?fields=name')
         .then(function (response) {
            // handle success
            // console.log(response.data);
            setCountries(response.data)
            // console.log(response.data)
            // setCountriesLoader(false)
         })
         .catch(function (error) {
            // handle error
            // console.log(error)
         })
         .finally(function () {
            // always executed
         })
   }, [])
   return (
      <PopUpContext.Consumer>
         {({ openPopUp }) =>
            reserve ? (
               <Reserve
                  countries={countries}
                  data={data}
                  close={close}
                  setPopUp={openPopUp}
               />
            ) : (
               <BookNow
                  countries={countries}
                  data={data}
                  close={close}
                  setPopUp={openPopUp}
               />
            )
         }
      </PopUpContext.Consumer>
   )
}

function Method({ name, icon }) {
   return (
      <div
         style={{
            backgroundImage: 'url(' + icon + ')',
         }}
         className=" flex h-24 w-24 cursor-pointer items-center justify-center rounded-xl bg-darktext bg-cover bg-center hover:bg-dark-400 hover:shadow-xs md:h-32 md:w-32"
      >
         {/* <img src={icon} alt={`${name}-icon`} /> */}
      </div>
   )
}

function BookNow({ data, countries }) {
   const [method, setMethod] = useState(null)
   const [checkout, setCheckout] = useState(null)
   const [email, setEmail] = useState(null)
   // console.log(data)

   // return checkout ? (
   return (
      <div className="flex flex-col items-center gap-2 px-4 pb-4 md:px-6 md:pb-6 ">
         <span className="dark:text-darktext mt-2 px-2 text-center text-5 font-bold text-dark-400 md:text-4">
            Paiement
         </span>
         <span className="dark:text-darktext mt-2 px-2 text-center text-3 text-dark-400 md:text-4">
            Choisissez ci-dessous le mode de paiement que vous souhaitez
            utiliser
         </span>
         <div className="justify-left flex w-full flex-wrap items-center gap-4 border-b p-2 pb-4">
            <Method
               onClick={() =>
                  setCheckout({ state: true, method: 'mastercard' })
               }
               icon={mastercard}
               name={'mastercard'}
            />
            <Method
               onClick={() => setCheckout({ state: true, method: 'visa' })}
               icon={visa}
               name={'visa'}
            />
            <Method
               onClick={() => setCheckout({ state: true, method: 'airtel' })}
               icon={airtel}
               name={'airtel'}
            />
            <Method
               onClick={() => setCheckout({ state: true, method: 'mpesa' })}
               icon={mpesa}
               name={'mpesa'}
            />
            <Method
               onClick={() => setCheckout({ state: true, method: 'orange' })}
               icon={orange}
               name={'orange'}
            />
         </div>
      </div>
      // ) : (
      //    <div className="w-full flex flex-col gap-2 items-center px-4 pb-4 md:px-6 md:pb-6 ">
      //       <div className="flex flex-col">
      //          <span className="px-2 mt-2 text-center font-bold md:text-5 text-4 text-dark-400 dark:text-darktext">
      //             You can send your reservation via the form below.
      //          </span>
      //          <span className="px-2 text-center text-4 text-dark-400 dark:text-darktext">
      //             <span className="font-semibold">Tour </span>:{' '}
      //             {data.tour.site_name}
      //          </span>
      //          <span className="px-2 text-center text-4 text-dark-400 dark:text-darktext">
      //             <span className="font-semibold">Number of People : </span>
      //             {`${data.people.adults} Adults, ${data.people.children} Enfants`}
      //          </span>
      //       </div>
      //       <div className="justify-center md:w-8/12  flex flex-col items-center w-full pb-3 gap-2">
      //          <div className="w-full flex flex-col gap-2 sm:gap-3">
      //             <TextInput
      //                rightIcon={HiUser}
      //                id="name"
      //                type="text"
      //                placeholder="Your name"
      //                required
      //             />
      //             <TextInput
      //                rightIcon={HiMail}
      //                id="email"
      //                placeholder="Your email"
      //                required
      //             />
      //             <TextInput
      //                rightIcon={HiPhone}
      //                id="number"
      //                placeholder="Contact number"
      //                required
      //             />
      //             {/* <TextInput id="country" placeholder="Your country" required /> */}
      //             {countries ? (
      //                <Select id="countries" required>
      //                   <option defaultValue={'CONGO DRC'} selected disabled>
      //                      select your country
      //                   </option>
      //                   {countries.map(({ name }) => (
      //                      <option value={name} key={name + 'book'}>
      //                         {name}
      //                      </option>
      //                   ))}
      //                </Select>
      //             ) : (
      //                <Spinner
      //                   color="success"
      //                   className="w-full flex justify-center items-center"
      //                />
      //             )}
      //          </div>
      //          <div className="w-full flex flex-col gap-2 justify-center items-center">
      //             <Button
      //                onClick={() => setCheckout({ state: true })}
      //                isProcessing={false}
      //                className="w-full bg-primary hover:bg-dark"
      //             >
      //                Go to Check Out
      //             </Button>
      //          </div>
      //       </div>
      //    </div>
      // )
   )
}
function Reserve({ data, countries, close, setPopUp }) {
   const [loading, setLoading] = useState(false)
   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm()

   const checkInputs = () => {
      return errors.mail || errors.telephone || errors.name || errors.message
   }

   const postData = (formData) => {
      setLoading(true)
      const dataToPost = new FormData()
      dataToPost.append('add_reservation', '')
      dataToPost.append('id_destination', data.tour.id_destination)
      dataToPost.append('site_id', data.tour.site_id)
      dataToPost.append('num_adults', data.people.adults)
      dataToPost.append('num_children', data.people.children)
      dataToPost.append('reservation_date', data.date)
      dataToPost.append('nom_reserveur', formData.name)
      dataToPost.append('email', formData.mail)
      dataToPost.append('telephone', formData.telephone)
      dataToPost.append('message', formData.message)
      dataToPost.append('country', formData.country)

      console.log(dataToPost.get('site_id'))

      axios
         .post(
            'https://admin.okapitouristiques.com/app/api/v1/reservation/',
            dataToPost,
         )
         .then((r) => {
            setPopUp({
               title: 'Notification',
               content: 'Réservation envoyé avec success ✅',
               accept: {
                  title: 'OK',
                  handler: close,
               },
            })
         })
         .catch((er) => {
            setPopUp({
               title: '‼️ Notification',
               content:
                  "Oups, Une erreur s'ait produite veuillez réessayer plus tard.",
               decline: {
                  title: 'OK',
                  // handler: close,
               },
            })
            setLoading(false)
         })
   }

   return (
      <div className="flex w-full flex-col items-center gap-2 px-4 pb-4 md:px-6 md:pb-6 ">
         <div className="flex flex-col">
            <span className="dark:text-darktext mt-2 px-2 text-center text-5 font-bold text-dark-400 md:text-5">
               Reservation
            </span>
            <span className="dark:text-darktext mt-2 px-2 text-center text-4 font-bold text-dark-400 md:text-5">
               Vous pouvez envoyer votre réservation via le formulaire
               ci-dessous.
            </span>
            <span className="dark:text-darktext px-2 text-center text-4 text-dark-400">
               <span className="font-semibold">Site </span>:{' '}
               {data.tour.site_name}
            </span>
            <span className="dark:text-darktext px-2 text-center text-4 text-dark-400">
               <span className="font-semibold">Nombre des personnes : </span>
               {`${data.people.adults} Adults, ${data.people.children} Enfants`}
            </span>
         </div>
         <div className="flex w-full  flex-col items-center justify-center gap-2 pb-3 md:w-8/12">
            <div className="flex w-full flex-col gap-2 sm:gap-3">
               <TextInput
                  rightIcon={HiUser}
                  {...register('name', { required: true })}
                  id="name"
                  color={errors.name && 'failure'}
                  type="text"
                  placeholder="Votre mom"
                  required
               />
               <TextInput
                  rightIcon={HiMail}
                  {...register('mail', {
                     required: true,
                     pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  })}
                  id="mail"
                  type="email"
                  aria-invalid={errors.mail ? 'true' : 'false'}
                  color={errors.mail && 'failure'}
                  placeholder="Votre email"
                  required
               />
               {/* {errors.mail && <p role="alert">{errors.mail.message}</p>} */}
               <TextInput
                  rightIcon={HiPhone}
                  {...register('telephone', {
                     required: true,
                     pattern: /^\+?\d[\d\s\.\-]+$/,
                  })}
                  id="number"
                  type="tel"
                  placeholder="Numero de telephone"
                  color={errors.telephone && 'failure'}
                  required
               />
               {countries ? (
                  <Select
                     {...register('country', { required: true })}
                     id="countries"
                     color={errors.country && 'failure'}
                     required
                  >
                     <option defaultValue={'CONGO DRC'} selected disabled>
                        Votre pays d'origine
                     </option>
                     {countries.map(({ name }) => (
                        <option value={name} key={name + 'serve'}>
                           {name}
                        </option>
                     ))}
                  </Select>
               ) : (
                  <Spinner
                     color="success"
                     className="flex w-full items-center justify-center"
                  />
               )}
               <Textarea
                  id="message"
                  color={errors.message && 'failure'}
                  {...register('message', { required: true })}
                  placeholder="Saisissez votre message ici..."
                  required
                  rows={4}
               />
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-2">
               <Button
                  // isProcessing={loading}
                  disabled={
                     errors.mail ||
                     errors.telephone ||
                     errors.name ||
                     errors.message
                  }
                  onClick={handleSubmit((dd) => checkInputs && postData(dd))}
                  className="w-full bg-primary hover:bg-dark"
               >
                  Send Reservation
               </Button>
            </div>
         </div>
      </div>
   )
}
