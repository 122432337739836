import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import people from '../../assets/imgs/people.png'

const Banner = styled.div`
   background-image: url('${(props) => (props.img ? props.img : people)}');
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`
export default function BlogCard({
   title,
   desc,
   isFormation,
   to,
   isEncours,
   cover,
   date,
   category,
}) {
   if (to === 1 || to === 2) {
      isEncours = true
   }

   return (
      <Link
         to={to.toString()}
         className="flex h-90 w-full min-w-60 flex-col justify-center rounded-xl bg-white shadow-xs hover:bg-bg md:w-120"
      >
         <Banner img={cover} className="h-50 w-full rounded-t-xl p-2 ">
            {isFormation && isEncours && (
               <div>
                  <span className="items-center justify-center rounded bg-white  p-1 text-4">
                     Encours
                  </span>
               </div>
            )}
         </Banner>
         <hr className="border-zinc00 border" />
         <Description
            title={title}
            desc={desc}
            isFormation={isFormation}
            date={date}
            category={category}
         />
      </Link>
   )
}

// function Banner({img}) {
//     return(
//         <div >
//         </div>
//     )
// }

function Description({ title, desc, isFormation, category, date }) {
   return (
      <div className="flex w-full flex-col gap-2 rounded-b-xl px-4 py-2">
         <div className="flex flex-row items-center justify-between">
            <span className="font-bold text-primary">
               {' '}
               {title ? title : 'Title'}
            </span>
         </div>
         <div className="flex flex-row items-center justify-between">
            <span className="rounded border px-2  py-1 text-3">
               {category ? category : 'Article'}
            </span>
            <span className="rounded border px-2  py-1 text-3">
               {date
                  ? date.toLocaleDateString()
                  : new Date().toLocaleDateString()}
            </span>
         </div>
         <span className="h-12 overflow-hidden text-4">
            {desc ? (
               <div dangerouslySetInnerHTML={{ __html: desc }}></div>
            ) : (
               'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur oloribus perspiciatis atque nemo? Debitis sint minima dolore labore, quaerat enim accusamus est at quae exercitationem doloribus quas dolore nesciunt iusto.'
            )}
         </span>
         <span className="font-semibold text-primary underline">
            Lire la suite...
         </span>
      </div>
   )
}
