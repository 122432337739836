import { Button, Carousel, TextInput } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import {} from 'styled-components'
import NotFound from '../../pages/404'
import ShareButtons from '../ShareButtons'

import { Breadcrumb, Spinner } from 'flowbite-react'
import { HiHome } from 'react-icons/hi'
import { TextBanner } from '../../pages/Home'
import DynamicMetaTags from '../DynamicMetaTags'
import People from '../People'
import Posts from '../Posts'
import { MyLoader } from '../Section'
import { sitesAssetLink } from '../SectionCard'
import Booking from '../TourBooking'
import { Stars } from '../TourView'

export default function TourDetails({ tours, destination }) {
   const params = useParams()
   const [dest, setDest] = useState()

   window.scrollTo(0, 0)

   useEffect(() => {
      setDest(tours.find((e) => Number(e.site_id) === Number(params.id)))
   }, [params, tours])

   // console.log(params.id)

   return (
      <div className="bg- mb-16 flex h-full  w-full flex-col items-center">
         <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
            <Routes>
               {/* {dest && (
                  <> */}
               <Route
                  path="/"
                  element={<TourDetail destination={destination} site={dest} />}
               />
               <Route path="*" element={<NotFound />} />
               {/* </>
               )} */}
            </Routes>
         </div>
      </div>
   )
}

function TourDetail({ destination, site }) {
   const params = useParams()
   return (
      <>
         <Breadcrumb
            aria-label="Default breadcrumb example"
            className=" w-full px-2 md:px-5"
         >
            <Breadcrumb.Item icon={HiHome}>
               <Link to={'/'} className="text-dark hover:font-bold">
                  Acceuil
               </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
               <Link to={'/destinations'} className="text-dark hover:font-bold">
                  Destinations
               </Link>
            </Breadcrumb.Item>
            {params.id && (
               <Breadcrumb.Item>
                  <Link
                     to={`/destinations/${destination.id_destination}`}
                     className="text-dark hover:font-bold"
                  >
                     {destination.id_destination}
                  </Link>
               </Breadcrumb.Item>
            )}
            {params.id && (
               <>
                  <Breadcrumb.Item>Sites</Breadcrumb.Item>{' '}
                  <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
               </>
            )}
         </Breadcrumb>
         {site ? (
            <div className="flex h-full w-full flex-col  items-center bg-white p-4">
               <div className="flex w-full flex-col items-center gap-2">
                  <div className="items-left flex w-full flex-col justify-center gap-1 p-2">
                     <span className="w-full text-4 font-semibold md:text-6">
                        {site
                           ? site.site_name && site.site_name
                           : 'La Méthamorphose'}
                     </span>
                     <Stars total={`460 reviews`} />
                  </div>
                  <DynamicMetaTags
                     title={
                        destination.destination_name + ' - ' + site.site_name
                     }
                     description={site.overview}
                     imageUrl={sitesAssetLink + site.images[0].img_name}
                  />
                  <div className="flex h-auto w-full flex-col items-center justify-between gap-2 overflow-hidden rounded-xl lg:h-135 lg:flex-row">
                     <Carousel
                        className="h-80 w-full bg-gray-200 lg:h-full lg:w-8/12"
                        // leftControl={}
                        slideInterval={5000}
                        pauseOnHover
                     >
                        {site ? (
                           site.images.map(({ img_name }) => (
                              <img
                                 alt={site.site_name}
                                 src={sitesAssetLink + img_name}
                                 key={site.site_id + img_name}
                              />
                           ))
                        ) : (
                           <Spinner
                              aria-label="Extra large spinner example"
                              size="xl"
                           />
                        )}
                     </Carousel>
                     <BookTour
                        tour={site}
                        className="h-full w-full lg:w-4/12"
                     />
                  </div>
                  <ShareButtons link={false} />

                  <div className="p-4">
                     <span className="text-6 font-bold">Aperçu detaillé</span>
                     <p className="mb-2 font-semibold">{site.overview}</p>
                     {/* <p>{site.overview}</p> */}
                  </div>
               </div>
            </div>
         ) : (
            <MyLoader />
         )}
         <TextBanner tile={'Annulation gratuite'} />
         <div className="dark:bg-dark-400 flex w-full flex-col gap-5 rounded-xl bg-white p-4 md:p-5">
            <Posts title={'🚀 Explorez nos meilleures expériences'} />
         </div>
      </>
   )
}

export function BookTour({ className, tour }) {
   const [date, setDate] = useState()
   const [maxPeople] = useState(false)
   const [modalState, setModalState] = useState(false)
   const [adults, setAdults] = useState(1)
   const [children, setChildren] = useState(1)
   const [people, setPeople] = useState({})

   // const changeDate = (dat) => {
   //    // console.log(date)
   //    const specificDate = new Date(dat)

   //    const year = specificDate.getFullYear()
   //    const month = (specificDate.getMonth() + 1).toString().padStart(2, '0')
   //    const day = specificDate.getDate().toString().padStart(2, '0')

   //    const formattedDate = `${year}-${month}-${day}`
   //    setDate(formattedDate)
   // }
   const closeModal = () => {
      setModalState(false)
   }
   const openModal = () => {
      setModalState(true)
   }

   useEffect(() => {
      setPeople({
         adults: adults,
         children: children,
      })
      console.log(date)
   }, [adults, children, date])

   return (
      <div className={`${className} `} slideInterval={5000} pauseOnHover>
         <div className="flex h-full w-full flex-col gap-2 rounded-xl border bg-white p-5 shadow-xs">
            <span className="font-bold md:text-5">
               à partir de{' '}
               {`${tour.currency ? tour.currency : '$'}${tour.price}.00`}
            </span>
            <span className="text-3 font-light underline">
               Garantie du prix le plus bas
            </span>
            <span className="font-bold md:text-5">
               Sélectionnez la date et les Voyageurs
            </span>
            <TextInput
               type="date"
               // defaultValue={}
               min={new Date().toISOString().split('T')[0]}
               onChangeCapture={(e) => setDate(e.target.value)}
            />
            {/* <Datepicker
               className="md-max:absolute md-max:mt-24"
               fo
               // inline
            /> */}
            {/* <TextInput
               icon={HiUser}
               id="email"
               placeholder="2 Adults"
               value="2 Adults"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            /> */}
            <People name={'adultes'} max={maxPeople} sendValue={setAdults} />
            <People name={'enfants'} max={maxPeople} sendValue={setChildren} />
            <Button
               onClick={openModal}
               disabled={!date || !people}
               color=""
               className="bg-[#196B49] text-white hover:bg-primary"
               type="primary"
            >
               {' '}
               Réserver{' '}
            </Button>
         </div>
         <Booking
            date={date}
            people={people}
            tour={tour}
            modalState={modalState}
            closeModal={closeModal}
         />
      </div>
   )
}
