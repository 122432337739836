import { HiLocationMarker, HiStar } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { sitesAssetLink } from '../SectionCard'

const Banner = styled.div`
   background-image: url(${(props) => props.bg});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   // width: 20rem;
`
export default function TourCard({ event }) {
   return (
      //    <EventModalContext.Consumer>
      //       {({ changeEvent }) => (
      <Link
         to={`/destinations/${event.id_destination}/${event.site_id}`}
         // onClick={() => changeEvent(event)}
         className="dark:border-none dark:bg-dark dark:hover:bg-dark-400 dark:hover:shadow-xs flex h-80 w-80 flex-col justify-center rounded-xl hover:bg-bg"
      >
         <Banner
            bg={sitesAssetLink + event.images[0].img_name}
            className="h-full w-80 rounded-xl  p-2 "
         ></Banner>
         {/* <hr className="border dark:border-none border-zinc00" /> */}
         <Description
            title={event.site_name}
            location={event.destination_name}
            time={'event.startTime'}
            price={event.price}
         />
      </Link>
      //    )}
      // </EventModalContext.Consumer>
   )
}

function Description({ title, date, location, price }) {
   return (
      <div className="dark:text-darktext flex w-80 flex-col gap-1 rounded-b-xl p-4 ">
         <div className="flex flex-col gap-1">
            <div className="gap flex flex-row items-center">
               {/* <span className="material-icons-round text-4">location_on</span> */}
               <HiLocationMarker />
               <span className="text-3">{location ? location : '13:00'}</span>
            </div>
            <div className="flex flex-row items-center justify-between">
               <span className="dark:text-darktext-white text-5 font-semibold">
                  {' '}
                  {title ? title : 'Title'}
               </span>
            </div>
         </div>
         <div className="flex flex-col gap-1">
            <Stars total={Math.floor(Math.random() * 1000)} />
            <div className="flex items-center gap-1">
               <span className="text-3">à partir de </span>
               <span className="text-4 font-bold">{price}.00$</span>
            </div>
         </div>
      </div>
   )
}

export function Stars({ total }) {
   return (
      <div className="gap flex items-center">
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-slate-400" />
         {/* <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-slate-400">star</span> */}
         <span className="ml-1 font-semibold">{total}</span>
      </div>
   )
}
