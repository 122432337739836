import { Link } from 'react-router-dom'
import { NavContext } from '../../utilis/Context'
// import { useState } from "react";
export default function Nav({ title, link, icon, HiCon }) {
   // const
   // const handleClick = () => {
   //     handler(title)
   // }

   // const Navv = styled.div`
   //    color: ${(props) => props.active && '#2b6cb0'};
   // `

   return (
      <NavContext.Consumer>
         {({ activeNav, handler }) => (
            <Link
               to={link}
               active={activeNav === title && true}
               style={{
                  color: activeNav === title && '#268B2A',
               }}
               onClick={() => handler(title)}
               className=" dark:text-slate-200 flex cursor-pointer flex-row items-center justify-center gap-2 text-4 font-semibold text-dark hover:text-primary"
            >
               {HiCon ? (
                  <HiCon className="text-5" />
               ) : (
                  <span className="material-icons-round text-5">{icon}</span>
               )}
               <span className="">{title}</span>
            </Link>
         )}
      </NavContext.Consumer>
   )
}
