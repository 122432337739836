import Contact from '../../components/Contact'
// import FAQ from '../../components/FAQ'
import Services from '../../components/Services'
import { PopUpContext } from '../../utilis/Context'

export default function OurServices() {
   document.title = 'Okapi Touristiques - Nos Services'
   window.scrollTo(0, 0)
   return (
      <PopUpContext.Consumer>
         {({ openPopUp }) => (
            <div className="bg- mb-16 flex h-full  w-full flex-col items-center md:mt-16">
               <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
                  <Services />
                  {/* <FAQ /> */}
                  <Contact setPopUp={openPopUp} />
               </div>
            </div>
         )}
      </PopUpContext.Consumer>
   )
}
