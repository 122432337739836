// import BlogCard from '../../components/BlogCard'
import { Link, Route, Routes } from 'react-router-dom'
// import FormationDetails from '../../components/FormationDetails'
import axios from 'axios'
import { Breadcrumb } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { HiHome } from 'react-icons/hi'
import Posts from '../../components/Posts'
import { MyLoader } from '../../components/Section'
import UnivCard from '../../components/UnivCard'
import UnivDetails from '../../components/UnivDetails'
import { apiLink } from '../../utilis/Context'

export default function Inscription() {
   // const youtube = '<iframe width="715" height="402" src="https://www.youtube.com/embed/ex3C1-5Dhb8" title="The Creator | Official Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
   window.scrollTo(0, 0)
   const [universities, setUniversities] = useState()
   const data = [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
   ]
   const fetchData = () => {
      axios
         .get(apiLink + 'api/v1/universities')
         .then((e) => setUniversities(e.data))
         .catch((r) => console.error(r))
   }

   useEffect(() => {
      fetchData()
   }, [])

   return (
      <div className="bg- mb-16 flex h-full  w-full flex-col items-center">
         <div className="items-left mt-4 flex w-full max-w-screen-xl flex-col gap-2 md:mt-16 md:gap-4">
            <Breadcrumb
               aria-label="Default breadcrumb example"
               className="px-5"
            >
               <Breadcrumb.Item icon={HiHome}>
                  <Link to={'/'} className="text-dark hover:font-bold">
                     Acceuil
                  </Link>
               </Breadcrumb.Item>
               <Breadcrumb.Item>Inscriptions</Breadcrumb.Item>
            </Breadcrumb>
            <Routes>
               <Route path="/" element={<Univs data={universities} />} />
               <Route
                  path="/:id"
                  element={<UnivDetails data={universities} />}
               />
            </Routes>
         </div>
         <div className="dark:bg-dark-400 flex w-full flex-col gap-5 rounded-xl bg-white p-4 md:p-5">
            <Posts title={'🚀 Top Destinations Sites'} />
         </div>
      </div>
   )
}

function Univs({ data }) {
   return (
      <div className=" flex h-full w-full flex-col gap-4 rounded-2xl border p-5 ">
         <div>
            <span className="rounded-xl bg-white px-2 py-1 text-4 font-semibold shadow-xs">
               📚 Universités
            </span>
         </div>
         <div className="grid w-full grid-cols-1 items-center justify-center gap-4 md:grid-cols-2  md:flex-row">
            {data ? (
               data.map((univ) => <UnivCard data={univ} key={univ.id} />)
            ) : (
               <MyLoader />
            )}
         </div>
      </div>
   )
}
