import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import DynamicMetaTags from '../DynamicMetaTags'
import { MyLoader } from '../Section'
import { articlesAssetLink } from '../SectionCard'
import ShareButtons from '../ShareButtons'

const Banner = styled.div`
   background-image: url('${(props) => props.bg}');
   background-position: center;
   background-repeat: no-repeat;

   height: 100%;
   width: 100%;
   // background-color: red;
`

export default function BlogDetails({ articles }) {
   const params = useParams()
   const [data, setData] = useState()
   window.scrollTo(0, 0)

   useEffect(() => {
      articles &&
         articles.length > 0 &&
         setData(
            articles.find((e) => Number(e.article_id) === Number(params.id)),
         )
   }, [params, articles])
   return data ? (
      <div className="flex h-full w-full flex-col  items-center rounded-xl border bg-white p-4">
         <DynamicMetaTags
            title={data.title}
            description={data.resume}
            imageUrl={articlesAssetLink + data.cover_img}
         />
         <div className="w-ful flex flex-col items-center gap-2 md:w-10/12">
            <div className="flex w-full justify-between pt-4">
               <span className="font-semibol rounded border px-2 py-1 text-3">
                  {data.category}
               </span>
               <div className="flex w-4/12 flex-row items-center justify-end">
                  <span className="rounded border px-2  py-1 text-3">
                     {new Date(data.date_added).toLocaleDateString('fr')}
                  </span>
               </div>
            </div>
            <div className="flex w-full flex-row items-center justify-between p-2 md:px-5">
               <span className="w-8/12 font-semibold md:text-5">
                  {data
                     ? data.title && data.title
                     : 'Christ au centre de toute Chose.'}
               </span>
            </div>
            <div className="flex h-50 w-full  justify-center  overflow-hidden rounded-xl border md:h-100">
               <Banner
                  bg={articlesAssetLink + data.cover_img}
                  // className="h-50 md:h-100 bg-white"
                  // alt="article_image"
                  // srcSet=""
               />
            </div>
            <ShareButtons link={false} className="pl-2 md:pl-5" />
            <div className="flex flex-col gap-2 p-4">
               {data.resume && (
                  <div
                     className="content"
                     dangerouslySetInnerHTML={{ __html: data.resume }}
                  ></div>
               )}
               {/* {`${data.resume}`
                  ? data.resume
                  : `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae
                  cupiditate fugiat possimus libero nobis corrupti. Laudantium
                  recusandae asperiores est aperiam at itaque harum fugit modi
                  voluptates. Eius dolor quam sequi! Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Suscipit animi accusamus
                  pariatur libero numquam a dolore, officiis ipsa repellat,
                  doloribus temporibus eos delectus vel odio exercitationem
                  nobis dolores quo officia?`} */}

               {/* <p className="text-justify">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae
                  cupiditate fugiat possimus libero nobis corrupti. Laudantium
                  recusandae asperiores est aperiam at itaque harum fugit modi
                  voluptates. Eius dolor quam sequi! Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Suscipit animi accusamus
                  pariatur libero numquam a dolore, officiis ipsa repellat,
                  doloribus temporibus eos delectus vel odio exercitationem
                  nobis dolores quo officia?
               </p> */}
               {/* <Quote /> */}
               {/* <p className="text-justify">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae
                  cupiditate fugiat possimus libero nobis corrupti. Laudantium
                  recusandae asperiores est aperiam at itaque harum fugit modi
                  voluptates. Eius dolor quam sequi! Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Suscipit animi accusamus
                  pariatur libero numquam a dolore, officiis ipsa repellat,
                  doloribus temporibus eos delectus vel odio exercitationem
                  nobis dolores quo officia?
               </p> */}
               {/* <div className="w-full p-2 md:p-5 flex flex-col gap-2">
                  <span className="font-semibold">La Video : </span>
                  <div className="flex h-60 ms:h-90 md:120 justify-center items-cente ">
                     <iframe
                        className="w-full h-full"
                        src="https://www.youtube.com/embed/Dz4rPyGLOgM"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                     ></iframe>
                  </div>
               </div> */}
            </div>
         </div>
      </div>
   ) : (
      <MyLoader />
   )
}

function Quote({ quote }) {
   return (
      <div className="flex w-full justify-center p-4">
         <div className="flex w-full max-w-160 flex-col items-center justify-center rounded-xl bg-bg p-4">
            {/* <span className=''>"</span> */}
            <span className="text-justify italic">
               Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
               excepturi, labore corrupti, necessitatibus voluptatem error sed
               voluptates, fugiat officiis dignissimos harum! Quidem hic, quos
               aliquid accusantium dicta aliquam accusamus vero?
            </span>
            {/* <span className=''>"</span> */}
         </div>
      </div>
   )
}
