import { Breadcrumb } from 'flowbite-react'
import { HiHome } from 'react-icons/hi'
import { Link, Route, Routes } from 'react-router-dom'
import BlogCard from '../../components/BlogCard'
import BlogDetails from '../../components/BlogDeatils'
import { articlesAssetLink } from '../../components/SectionCard'
import { DestinationContext } from '../../utilis/Context'

export default function Blog() {
   document.title = 'Okapi Touristiques - Blog'
   return (
      <DestinationContext.Consumer>
         {({ articles }) => (
            <div className="bg- flex h-full w-full flex-col items-center justify-start gap-2 md:mt-16">
               <div className="flex w-full flex-col p-5">
                  <Breadcrumb
                     aria-label="Default breadcrumb example"
                     className="px-5"
                  >
                     <Breadcrumb.Item icon={HiHome}>
                        <Link to={'/'} className="text-dark hover:font-bold">
                           Acceuil
                        </Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Articles</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
                  {/* <NavBar /> */}
                  <Routes>
                     <Route path="/" element={<Blogs articles={articles} />} />
                     <Route
                        path="/:id"
                        element={<BlogDetails articles={articles} />}
                     />
                  </Routes>
                  {/* <Footer/> */}
               </div>
            </div>
         )}
      </DestinationContext.Consumer>
   )
}

function Blogs({ articles }) {
   return (
      <div className=" flex h-full w-full flex-col gap-4 rounded-2xl p-5 md:border">
         <div>
            <span className="rounded-xl bg-white px-2 py-1 text-4 font-semibold shadow-xs">
               🗞 Nos Articles
            </span>
         </div>
         <div className="grid w-full grid-cols-1 items-center gap-4 md:grid-cols-2  md:flex-row">
            {articles &&
               articles.map(
                  ({
                     article_id,
                     resume,
                     title,
                     cover_img,
                     category,
                     date_added,
                  }) => (
                     <BlogCard
                        date={new Date(date_added)}
                        desc={resume}
                        title={title}
                        cover={articlesAssetLink + cover_img}
                        category={category}
                        isFormation={false}
                        to={article_id}
                        key={article_id}
                     />
                  ),
               )}
         </div>
      </div>
   )
}
