import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import people from '../../assets/imgs/people.png'

const Banner = styled.div`
   background-image: url(${people});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`
export default function BookCard({ title, desc, isFormation, to }) {
   return (
      <Link
         to={to.toString()}
         className="h-6/12 flex h-full max-h-50 min-w-60 flex-row justify-center rounded-xl bg-white shadow-xs hover:bg-bg"
      >
         <Banner className="h-full w-full rounded-l-xl p-2 ">
            {isFormation && (
               <span className="rounded bg-white p-1 text-4 ">Encours</span>
            )}
         </Banner>
         <hr className="border-zinc00 border" />
         <Description title={title} desc={desc} isFormation={isFormation} />
      </Link>
   )
}

function Description({ title, desc, isFormation }) {
   return (
      <div className="gap- flex h-full w-full flex-col rounded-b-xl px-4 pb-2 pt-4">
         <div className="flex flex-row items-center justify-between">
            <span className="font-semibold"> {title ? title : 'Title'}</span>
            <span className="rounded border px-2  py-1 text-3">
               {' '}
               12-01-2021
            </span>
         </div>
         <span className="overflow-hidden text-3">
            {desc
               ? desc
               : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur oloribus perspiciatis atque nemo? Debitis sint minima dolore labore, quaerat enim accusamus est at quae exercitationem doloribus quas dolore nesciunt iusto.'}
         </span>
         <div className="h-4/12 mr-2 mt-2 flex w-full items-center justify-center rounded-xl py-1 text-primary hover:bg-primary hover:text-white">
            <span className="py- cursor-pointer rounded-2xl px-2 text-3 font-semibold md:text-4">
               Obtenir
            </span>
         </div>
      </div>
   )
}
