import { Helmet } from 'react-helmet'

const DynamicMetaTags = ({ title, description, imageUrl }) => {
   return (
      <div>
         <Helmet>
            {/* Facebook Meta Tags */}
            <meta property="og:title" content={title} />
            <meta
               property="og:url"
               content="https://www.okapitouristiques.com"
            />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />
            {/* Add or remove other Facebook meta tags as needed */}

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
            {/* Add or remove other Twitter meta tags as needed */}
         </Helmet>
      </div>
   )
}

export default DynamicMetaTags
