import { HiLocationMarker, HiStar } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { sitesAssetLink } from '../SectionCard'
// import { Stars } from '../TourCard'

const Banner = styled.div`
   background-image: url(${(props) => props.img});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`
export default function TourView({ isSellOut, to, tour }) {
   return (
      <Link
         to={`${to}`}
         className="flex h-50 min-w-60 flex-row justify-center rounded-xl border bg-white p-2 hover:bg-bg md:h-80 md:p-5"
      >
         <Banner
            img={sitesAssetLink + tour.images[0].img_name}
            className="h-full w-5/12 rounded-xl p-2 "
         >
            {isSellOut && (
               <span className="rounded bg-white p-1 text-4 ">Sell Out</span>
            )}
         </Banner>
         {/* <hr className="border border-zinc00" /> */}
         <Description
            title={tour.site_name}
            desc={tour.overview}
            location={tour.destination_name}
            price={tour.price}
         />
      </Link>
   )
}
function Description({ title, date, location, desc, price }) {
   return (
      <div className="dark:text-darktext flex w-7/12 flex-col gap-1 rounded-b-xl p-2 md:p-4 ">
         <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center justify-between">
               <span className="dark:text-darktext-white text-4 font-bold md:text-6">
                  {' '}
                  {title ? title : 'Title'}
               </span>
               <div className="gap  items-left hidden flex-col md:flex">
                  <span className="text-right text-3">from</span>
                  <span className="font-bold md:text-5">{price},00$</span>
               </div>
            </div>
            <div className="flex flex-row items-center gap-1">
               {/* <span className="material-icons-round text-4">location_on</span> */}
               <HiLocationMarker className="text-5" />
               <span className="text-4">
                  {location ? location : 'location'}
               </span>
            </div>
         </div>
         <div className="flex flex-col gap-1">
            <Stars total={Math.floor(Math.random() * 1000)} />

            <div className="hidden  items-center gap-1 md:flex">
               <span className="text-3 font-light md:text-4">
                  {desc} <span className="font-semibold underline">More</span>
               </span>
            </div>
         </div>
         {/* <div className="flex flex-row gap-1 items-center">
            <span className="material-icons-round text-4">access_time</span>
            <span className="text-4 font-semibold">
               {location ? location : 'location'}
            </span>
         </div> */}
         <div className="flex flex-row items-center gap-1">
            <span className="material-icons-round text-4">check</span>
            <span className="text-4 font-semibold">{'Free Cancelation'}</span>
         </div>
         <div className="gap items-left flex flex-row items-center gap-1 md:hidden">
            <span className="text-3">from</span>
            <span className="text-4 font-bold md:text-5">{price},00$</span>
         </div>
      </div>
   )
}

export function Stars({ total }) {
   return (
      <div className="gap flex items-center">
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-orange-500" />
         <HiStar className="text-5 text-slate-400" />
         {/* <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-orange-500">star</span>
         <span className="material-icons-round text-slate-400">star</span> */}
         <span className="ml-1 font-semibold">{total}</span>
      </div>
   )
}
