import { Tooltip } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { ExploreModalContext, NavContext } from '../../utilis/Context'

export default function NavIcon({ name, title, link, onClick, HiCon }) {
   // console.log(active)

   return (
      <ExploreModalContext.Consumer>
         {({ openModal }) => (
            <NavContext.Consumer>
               {({ activeNav, handler }) => (
                  <Tooltip
                     className="text-3"
                     content={title}
                     placement="top"
                     style="light"
                  >
                     <div
                        onClick={() => {
                           title.toLocaleLowerCase() === 'explore' &&
                              openModal(true)
                           onClick && onClick()
                        }}
                     >
                        <Link className="dark:text-white flex flex-col items-center justify-center text-dark">
                           {HiCon ? (
                              <HiCon className="text-8" />
                           ) : (
                              <span className="material-icons-round text-8">
                                 {name}
                              </span>
                           )}
                        </Link>
                     </div>
                  </Tooltip>
               )}
            </NavContext.Consumer>
         )}
      </ExploreModalContext.Consumer>
   )
}
