// import { events } from '../../utilis/Data'
import { DestinationContext } from '../../utilis/Context'
import { MyLoader } from '../Section'
import TourCard from '../TourCard'

export default function Posts({ title }) {
   return (
      <DestinationContext.Consumer>
         {({ sites }) => (
            <div className="dark:bg-dark-400 dark:border dark:border-dark shadow-s flex h-full w-full flex-col gap-2 rounded-2xl bg-white pb-2 pt-4">
               <div className="mb-4 flex flex-col items-center  justify-center gap-2">
                  <span
                     id="home"
                     className="dark:bg-dark dark:text-darktext roundedxl rounded-xl bg-bg px-4 py-2 text-4 font-bold text-dark md:text-5 md-max:font-semibold lg:text-6"
                  >
                     {title}
                  </span>
               </div>
               <div className="justify-left flex h-full w-full flex-row items-center gap-5 overflow-scroll py-3">
                  {sites && sites.length > 0 ? (
                     sites.map((site) => (
                        <TourCard
                           event={site}
                           key={
                              site.site_id +
                              site.id_destination +
                              'events' +
                              Math.floor(Math.random() * 122)
                           }
                        />
                     ))
                  ) : (
                     <MyLoader />
                  )}
               </div>
            </div>
         )}
      </DestinationContext.Consumer>
   )
}
