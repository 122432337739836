import axios from 'axios'
import { createContext, useEffect, useState } from 'react'

// export const apiLink = 'http://192.168.16.3/okapitouristique/'
// const assetsLink = 'http://192.168.16.3/okapitouristique/'
export const apiLink = 'https://admin.okapitouristiques.com/app/'

export const session = window.localStorage

export const NavContext = createContext()

export const NavProvider = ({ children }) => {
   const [activeNav, setActiveNav] = useState('Home')

   const handler = (title) => {
      setActiveNav(title)
   }

   return (
      <NavContext.Provider value={{ activeNav, handler }}>
         {children}
      </NavContext.Provider>
   )
}

export const SessionContext = createContext()

export const getLoggedUser = () => {
   return JSON.parse(session.getItem('e04su_ktt'))
}

export const SessionProvider = ({ children }) => {
   const [loggedUser, setloggedUser] = useState()
   // const [effect, setEffect] = useState()

   // const navigate = useNavigate()

   const StartSession = (user) => {
      session.getItem('e04su_ktt') === null &&
         session.setItem('e04su_ktt', JSON.stringify(user))
      StoreSession(user)
      // setEffect(true)
      // console.log(loggedUser)
      // window.location.assign('/dashboard')
      // setEffect()
   }

   const StoreSession = (user) => {
      user && user.email && setloggedUser(user)
      // : window.location.assign('/login')
   }

   const DestroySession = () => {
      session.removeItem('e04su_ktt')
      setloggedUser()
      // window.location.assign('/')
   }

   useEffect(() => {
      !loggedUser && StoreSession(JSON.parse(session.getItem('e04su_ktt')))
   }, [loggedUser])

   return (
      <SessionContext.Provider
         value={{ loggedUser, StartSession, DestroySession }}
      >
         {children}
      </SessionContext.Provider>
   )
}

export const EventModalContext = createContext()

export const EventModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [event, setEvent] = useState(null)

   const closeModal = () => {
      setModalState(false)
   }
   const openModal = () => {
      setModalState(true)
   }
   const changeEvent = (ev) => {
      setEvent(ev)
      // console.log(ev)
      setModalState(true)
   }

   return (
      <EventModalContext.Provider
         value={{ modalState, closeModal, openModal, event, changeEvent }}
      >
         {children}
      </EventModalContext.Provider>
   )
}

export const DestinationContext = createContext()

export const DestinationProvider = ({ children }) => {
   const [destinations, setDestinations] = useState([])
   const [sites, setSites] = useState([])
   const [articles, setArticles] = useState([])
   const [online, setOnline] = useState()

   window.addEventListener('online', () => setOnline(true))
   window.addEventListener('offline', () => setOnline(false))
   // console.log(window.navigator.onLine)
   useEffect(() => {
      fetchData()
      fetchSites()
      fetchArticles()
      // (!destinations || destinations.length < 0) && fetchData()
   }, [online, window.navigator.onLine])

   const fetchData = () => {
      axios
         .get(apiLink + 'api/v1/destinations')
         .then((e) => setDestinations(e.data))
         .catch((r) => console.error(r))
   }
   const fetchArticles = () => {
      axios
         .get(apiLink + 'api/v1/articles')
         .then((e) => setArticles(e.data))
         .catch((r) => console.error(r))
   }
   const fetchSites = () => {
      axios
         .get(apiLink + 'api/v1/sites')
         .then((e) => setSites(shuffle(e.data)))
         .catch((r) => console.error(r))
   }

   return (
      <DestinationContext.Provider value={{ destinations, sites, articles }}>
         {children}
      </DestinationContext.Provider>
   )
}

export const CategoryModalContext = createContext()

export const CategoryModalProvider = ({ children }) => {
   const [destinations, setDestinations] = useState()

   useEffect(() => {
      fetchData()
   }, [])

   const fetchData = () => {
      axios
         .get(apiLink + 'api/v1/destinations')
         .then((e) => setDestinations(e.data))
         .catch((r) => console.error(r))
   }

   return (
      <CategoryModalContext.Provider value={{ destinations }}>
         {children}
      </CategoryModalContext.Provider>
   )
}

export const ExploreModalContext = createContext()

export const ExploreModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [dateModalState, setDateModalState] = useState(false)
   const [category, setCategory] = useState(null)
   const [date, setDate] = useState(null)

   const closeModal = () => {
      setModalState(false)
      setDate(null)
   }

   const closeDateModal = () => {
      setDateModalState(false)
      setCategory(null)
   }
   const openModal = () => {
      setModalState(true)
   }
   const changeCategory = (cat) => {
      setCategory(cat)
   }

   const changeDate = (dat) => {
      // setSelectedDate(date);
      const da = new Date(dat)
      setDate(da.toLocaleDateString())
   }

   useEffect(() => {
      date && setDateModalState(true)
   }, [date])

   return (
      <ExploreModalContext.Provider
         value={{
            modalState,
            dateModalState,
            closeModal,
            closeDateModal,
            openModal,
            category,
            changeCategory,
            date,
            changeDate,
         }}
      >
         {children}
      </ExploreModalContext.Provider>
   )
}

export const FormModalContext = createContext()

export const FormModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [loginModalState, setLoginModalState] = useState(false)
   const [view, setView] = useState(null)

   const closeModal = () => {
      setLoginModalState(false)
   }

   const changeForm = (vw) => {
      setView(vw)
      !loginModalState && setLoginModalState(true)
   }

   return (
      <FormModalContext.Provider
         value={{ modalState, loginModalState, closeModal, view, changeForm }}
      >
         {children}
      </FormModalContext.Provider>
   )
}
export const PopUpContext = createContext()

export const PopUpProvider = ({ children }) => {
   const [popUpState, setPopUpState] = useState()

   const closePopUp = () => {
      setPopUpState(null)
   }
   const openPopUp = (popup) => {
      setPopUpState(popup)
   }

   return (
      <PopUpContext.Provider value={{ popUpState, closePopUp, openPopUp }}>
         {children}
      </PopUpContext.Provider>
   )
}
export const CartContext = createContext()

export const CartProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [cart, setCart] = useState([])
   const [cartTotal, setCartTotal] = useState(0)

   const closeModal = () => {
      setModalState(false)
   }
   const openModal = () => {
      setModalState(true)
   }
   const addToCart = (data) => {
      // console.log(cart)
      setCart([...cart, data])
      openModal()
   }
   const deleteFromCart = (data) => {
      const holder = cart.filter(({ id }) => {
         return id !== data
      })
      cart.length > 1 ? setCart(holder) : setCart([])
   }
   const clearCart = (data) => {
      // console.log(cart)
      setCart([])
      // closeModal()
   }
   const getCartTotal = () => {
      let total = 0
      // cart.forEach
      if (cart.length > 0) {
         cart.forEach(
            ({ ticket, tickets }) => (total += ticket.price * tickets.length),
            // console.log(ticket.price)
         )
         setCartTotal(total)
      } else {
         setCartTotal(0)
      }
   }

   useEffect(() => {
      getCartTotal()
   }, [cart])

   return (
      <CartContext.Provider
         value={{
            modalState,
            closeModal,
            openModal,
            cart,
            addToCart,
            clearCart,
            cartTotal,
            deleteFromCart,
         }}
      >
         {children}
      </CartContext.Provider>
   )
}

function shuffle(array) {
   let currentIndex = array.length,
      randomIndex

   // While there remain elements to shuffle.
   while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
         array[randomIndex],
         array[currentIndex],
      ]
   }

   return array
}
