// import BlogCard from '../../components/BlogCard'
// import FormationDetails from '../../components/FormationDetails'
import logo from '../../assets/icons/okapi.png'
import BookCard from '../../components/BookCard'
import Contact from '../../components/Contact'
import { PopUpContext } from '../../utilis/Context'

export default function AboutUs() {
   document.title = 'Okapi Touristiques - Apropos de Nous'
   window.scrollTo(0, 0)
   return (
      <div className="bg- mb-16 mt-4 flex  h-full w-full flex-col items-center md:mt-14">
         <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
            <div className="flex flex-col gap-2 p-5 text-justify text-5">
               <span className="mt-2 text-8 font-semibold text-primary">
                  {' '}
                  Qui sommes-nous ?{' '}
               </span>{' '}
               <div className="flex flex-col gap-5 md:flex-row ">
                  <img src={logo} alt="logo" className="w-60" />
                  <p className="">
                     Nous sommes
                     <span className="font-semibold"> OKAPI TOURISTIQUE </span>,
                     une Société anonyme de droit Congolais (S.A), créée depuis
                     2018 par le numéro national d’identification
                     01-F4300-N77201H en application de l’esprit de l’ordonnance
                     n° 73/236 du 13 août 1973 portant institution des numéros
                     nationaux d’identification,
                     <p>
                        Nous intervenons dans le domaine du tourisme, la
                        facilitation d’obtention des passeports, demande de
                        visas touristiques pour les nationaux et étrangers, les
                        randonnés lacustres, la vente des billets en ligne, la
                        facilitation d’hébergement, les visites guidées de la
                        ville de Bukavu, Goma, de la République démocratique du
                        Congo en générales, et plusieurs autres activités
                        touristiques.
                     </p>
                  </p>
               </div>
               <p>
                  <span className="font-semibold"> OKAPI TOURISTIQUE </span>{' '}
                  Explorer l'inexploré, Vivez l'extraordinaire Depuis 2018,
                  <span className="font-semibold">
                     {' '}
                     OKAPI TOURISTIQUE{' '}
                  </span>{' '}
                  ouvre les portes de la découverte et de l'aventure. Notre
                  mission est de vous offrir une expérience unique et captivante
                  dans certains des endroits les plus fascinants de la
                  République Démocratique du Congo et principalement au
                  Nord-Kivu et sud Kivu.
               </p>
               <span className="mt-2 text-8 font-semibold text-primary">
                  {' '}
                  Mission{' '}
               </span>{' '}
               <p>
                  Notre mission est de vous offrir une expérience unique et
                  captivante dans certains des endroits les plus accent-color
                  fascinants de la République Démocratique du Congo et
                  principalement au Nord-Kivu et sud Kivu.
               </p>
            </div>
            <PopUpContext.Consumer>
               {({ openPopUp }) => <Contact setPopUp={openPopUp} />}
            </PopUpContext.Consumer>
         </div>
      </div>
   )
}

function Blogs({ data }) {
   return (
      <div className=" flex h-full w-full flex-col gap-4 rounded-2xl border p-5">
         <div>
            <span className="rounded-xl bg-white px-2 py-1 text-4 font-semibold shadow-xs">
               📚 Livres
            </span>
         </div>
         <div className="grid w-full grid-cols-1 items-center gap-4 md:grid-cols-2  md:flex-row">
            {data.map(({ id }) => (
               <BookCard to={id} key={id} />
            ))}
         </div>
      </div>
   )
}
