// import { CustomFlowbiteTheme } from 'flowbite-react';
import { Carousel } from 'flowbite-react'
import { HiArrowCircleRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { DestinationContext } from '../../utilis/Context'
import { MyLoader } from '../Section'
import { sitesAssetLink } from '../SectionCard'

export default function HomeBanner(params) {
   return (
      <DestinationContext.Consumer>
         {({ sites }) => (
            <div className="flex w-full flex-col items-center gap-5 p-2 md:flex-row">
               <div className=" h-[40dvh] w-full rounded-md bg-gray-200 sm:h-[50dvh] md:h-[50dvh] lg:h-[60dvh] xl:h-[75dvh] 2xl:h-[500px]">
                  <Carousel
                     // leftControl={}
                     slideInterval={10000}
                     // pauseOnHover
                  >
                     <video
                        className="w-full"
                        loop
                        muted
                        autoPlay={true}
                        src="https://admin.okapitouristiques.com/assets/video/into.mp4"
                        alt="video intro"
                     ></video>
                     {sites && sites.length > 0 ? (
                        sites
                           .slice(0, 5)
                           .map((site) => (
                              <Tour
                                 img={sitesAssetLink + site.images[0].img_name}
                                 key={site.site_id + site.id_destination}
                                 title={site.site_name}
                                 link={
                                    'destinations/' +
                                    site.id_destination +
                                    '/' +
                                    site.site_id
                                 }
                              />
                           ))
                     ) : (
                        <MyLoader />
                     )}
                  </Carousel>
               </div>
            </div>
         )}
      </DestinationContext.Consumer>
   )
}

function Tour({ img, title, link }) {
   return (
      <div
         style={{
            backgroundImage: 'url(' + img + ')',
         }}
         className="flex h-full w-full flex-col  items-center justify-center bg-opacity-10 bg-cover bg-center bg-no-repeat"
      >
         <div className="flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-30 p-4">
            <span className="text-6 font-bold text-white md:text-10 ">
               {title}
            </span>
            <span className="text-center font-semibold text-white">
               {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. */}
            </span>
            <Link
               to={link}
               className=" mt-4 flex cursor-pointer items-center gap-1 rounded bg-primary py-2 pl-4 pr-2 font-semibold text-darktext hover:bg-green-600"
            >
               <span>Visit site</span>
               <HiArrowCircleRight className="text-5" />
               {/* <span className="material-icons-round">
                  keyboard_arrow_right
               </span> */}
            </Link>
         </div>
      </div>
   )
}
