import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { apiLink } from '../../utilis/Context'

const Banner = styled.div`
   background-image: url(${(props) => props.bg});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`

export const destinationsAssetLink = apiLink + 'images/destinations/'
export const articlesAssetLink = apiLink + 'images/articles/'
export const sitesAssetLink = apiLink + 'images/sites/'
export const univsAssetLink = apiLink + 'images/universities/'
// export const destinationsAssetLink =
//    'https://admin.okapitouristiques.com/app/images/destinations/'
// export const articlesAssetLink =
//    'https://admin.okapitouristiques.com/app/images/articles/'
// export const sitesAssetLink =
//    'https://admin.okapitouristiques.com/app/images/sites/'
export default function DestinationCard({ event }) {
   return (
      <Link
         to={'/destinations/' + event.id_destination}
         style={{
            backgroundImage: 'url(' + destinationsAssetLink + event.cover + ')',
         }}
         className="h-50 min-w-80 cursor-pointer flex-col justify-center rounded-xl bg-white bg-cover bg-center hover:bg-bg sm:w-90 md:h-40 md:w-80 md-max:w-full "
      >
         <div className="justify- flex h-50 min-w-80 flex-col items-center rounded-xl bg-black bg-opacity-30 p-2  hover:bg-opacity-20 sm:w-90 md:h-40 md:w-80 md-max:w-full">
            <div className="justify-left flex h-[40%] w-full flex-col items-start">
               <span className="self-end rounded bg-red-500 px-2 py-1 text-3 font-semibold text-white">
                  {event.sites.length} Sites
               </span>
            </div>
            <span className="h-[60%] w-full text-center text-6 font-bold text-darktext-white">
               {event.destination_name}
            </span>
         </div>
      </Link>
   )
}
