import { Breadcrumb } from 'flowbite-react'
import { HiHome } from 'react-icons/hi'
import { Link, Route, Routes } from 'react-router-dom'
import DestinationDetails from '../../components/DestinationDetails'
import { MyLoader } from '../../components/Section'
import SectionCard from '../../components/SectionCard'
import { DestinationContext } from '../../utilis/Context'
import { TextBanner } from '../Home'

export default function Destinations() {
   document.title = 'Okapi Touristiques - Destinations'
   window.scrollTo(0, 0)

   return (
      <DestinationContext.Consumer>
         {({ destinations }) => (
            <div className="mt-2 flex h-full w-full  flex-col items-center md:mt-16 ">
               <div className="items-left flex w-full max-w-screen-xl flex-col gap-2 md:gap-4">
                  <Routes>
                     <Route
                        path="/"
                        element={<DestinationsList data={destinations} />}
                     />
                     <Route
                        path="/:id/*"
                        element={
                           <DestinationDetails destinations={destinations} />
                        }
                     />
                  </Routes>
               </div>
            </div>
         )}
      </DestinationContext.Consumer>
   )
}

function DestinationsList({ data }) {
   return (
      <>
         <Breadcrumb aria-label="Default breadcrumb example" className="px-5">
            <Breadcrumb.Item icon={HiHome}>
               <Link to={'/'} className="text-dark hover:font-bold">
                  Acceuil
               </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Destinations</Breadcrumb.Item>
         </Breadcrumb>
         <div className="dark:bg-dark-400 hide-scroll flex h-auto w-auto flex-col  gap-2 overflow-scroll px-5 sm:pt-4 md:w-full md:gap-4">
            <div className="items-left justify-left mb-1 flex w-full flex-row gap-2">
               <span className="dark:bg-dark dark:text-darktext roundedxl rounded-xl bg-bg px-4 py-2 text-4  font-bold text-dark md-max:font-semibold">
                  {'🚀 Nos Destinations'}
               </span>
            </div>
            <div className="flex min-h-60 w-auto flex-row flex-wrap items-center justify-center gap-5 overflow-scroll pb-4 md:w-full">
               {data && data.length > 0 ? (
                  data.map((destination) => (
                     <SectionCard
                        event={destination}
                        key={destination.id_destination}
                     />
                  ))
               ) : (
                  <MyLoader />
               )}
            </div>
         </div>
         <TextBanner
            title="Gardez les choses flexibles"
            text="Réservez maintenant et payez plus tard pour sécuriser les activités que vous ne voulez pas manquer sans être bloqué."
         />
      </>
   )
}
