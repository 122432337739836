import { useState } from 'react'
import { styled } from 'styled-components'
import img from '../../assets/imgs/prog2.jpeg'
import ShareButtons from '../ShareButtons'

const Banner = styled.div`
   background-image: url('${(props) => props.bg}');
   background-position: center;
   background-repeat: no-repeat;

   //    height : 100%;
   width: 100%;
   // background-color: red;
`

export default function BookDetails() {
   const [data, setData] = useState()
   return (
      <div className="flex h-full w-full flex-col  items-center rounded-xl border bg-white p-4">
         <div className="w-ful flex flex-col items-center gap-2 md:w-10/12">
            <div className="flex w-full justify-between pt-4">
               <span className="font-semibol rounded border px-2 py-1 text-3">
                  Books / Details{' '}
               </span>
               {/* <div className="w-4/12 flex flex-row justify-end items-center">
                  <span className="text-3 px-2 py-1  rounded border">
                  {' '}
                  12-01-2021
                  </span>
               </div> */}
            </div>
            <ShareButtons />
            <div className="flex h-60 w-full  justify-center  overflow-hidden rounded-xl border md:h-120">
               <Banner
                  bg={img}
                  // className="h-50 md:h-100 bg-white"
                  // alt="article_image"
                  // srcSet=""
               />
            </div>
            <div className="flex w-full flex-row items-center justify-between gap-1 p-2 md:p-5">
               <span className="w-full text-4 font-semibold md:text-5">
                  {data ? data.title && data.title : 'La Méthamorphose'}
               </span>
               {/* <div className="w-full flex flex-col sm:flex-row items-center justify-between"> */}
               {/* <div className="w-full flex flex-row gap-1 items-center justify-end pl-2 md:pl-5 "> */}
               {/* <span className="material-icons-round">ticket</span> */}
               <span className="cursor-pointer rounded border bg-primary px-2 py-1  text-4 font-semibold text-white">
                  {' '}
                  Obtenir
               </span>
               {/* </div> */}
               {/* </div> */}
            </div>

            <div className="p-4">
               <span className="font-semibold">Résumé :</span>
               <p className="mb-2 font-semibold">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                  id aperiam, nihil vitae dolorem molestiae qui incidunt
                  aspernatur molestias, itaque minus, sed porro pariatur illo
                  enim autem fuga eaque odit.
               </p>
               <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae
                  cupiditate fugiat possimus libero nobis corrupti. Laudantium
                  recusandae asperiores est aperiam at itaque harum fugit modi
                  voluptates. Eius dolor quam sequi! Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Suscipit animi accusamus
                  pariatur libero numquam a dolore, officiis ipsa repellat,
                  doloribus temporibus eos delectus vel odio exercitationem
                  nobis dolores quo officia?
               </p>
            </div>
         </div>
      </div>
   )
}
